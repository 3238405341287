import _ from "lodash";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



export const base = css`
height: auto;
min-height: 50px;
resize: vertical;
`;


export const error = css`
`;


export const success = css`
`;
