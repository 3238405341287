import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { LoanDto, CardResponseDto } from "@myk";


interface CommPreferences {
  allowEmail: boolean,
  allowSms: boolean,
  allow3CxCalls: boolean
}

interface RestructureState {
  calculatingDatesInProgress?: boolean,
  amounts?: string[],
  agreementType?: string,
  paymentDates?: string[],
  customerAccounts?: any[],
  tableViewed?: boolean,
  availableDates?: string[],
  currentTime?: string | Date,
  holidays?: string[],
  communicationPreferences?: CommPreferences,
  loan?: LoanDto,
  cards?: CardResponseDto[],
  termsAndConditions?: any,
  loanExplanation?: any
}

const initialState: RestructureState = {
};

const restructureSlice = createSlice({
  name: "restructure",
  initialState,
  reducers: {
    setCalculatingDatesInProgress(state, action: PayloadAction<boolean>) {
      state.calculatingDatesInProgress = action.payload;
    },
    setAmounts(state, action: PayloadAction<string[]>) {
      state.amounts = action.payload;
    },
    getAgreementsStart(state, action: PayloadAction<any>) {
      state.agreementType = action.payload;
    },
    getAgreementsSuccess(state, action) {
      state.termsAndConditions = action.payload.termsAndConditions;
      state.loanExplanation = action.payload.loanExplanation;
    },
    passPaymentDates(state, action: PayloadAction<string[]>) {
      state.paymentDates = action.payload;
    },
    requestViewTable(state, action: PayloadAction<any>) {
      return state;
    },
    passCustomerAccounts(state, action: PayloadAction<any>) {
      state.customerAccounts = action.payload;
    },
    setRestructureTableViewed(state, action: PayloadAction<boolean>) {
      state.tableViewed = action.payload;
    },
    getAvailableDates(state, action: PayloadAction<string[]>) {
      state.availableDates = action.payload;
    },
    passCurrentTime(state, action: PayloadAction<string | Date>) {
      state.currentTime = action.payload;
    },
    getHolidaysList(state, action: PayloadAction<string[]>) {
      state.holidays = action.payload;
    },
    getCommunicationPreferences(state, action: PayloadAction<CommPreferences>) {
      state.communicationPreferences = action.payload;
    },
    getLoan(state, action: PayloadAction<LoanDto>) {
      state.loan = action.payload;
    },
    getCards(state, action: PayloadAction<CardResponseDto[]>) {
      state.cards = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default restructureSlice.reducer;
export const {
  setCalculatingDatesInProgress,
  setAmounts,
  getAgreementsStart,
  getAgreementsSuccess,
  passPaymentDates,
  requestViewTable,
  passCustomerAccounts,
  setRestructureTableViewed,
  getAvailableDates,
  passCurrentTime,
  getHolidaysList,
  getCommunicationPreferences,
  getLoan,
  getCards,
  destroy
} = restructureSlice.actions;
export const restructureActions = restructureSlice.actions;
