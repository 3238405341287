// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState } from "./formMetaHelpers";

import * as styles from "./CheckboxField.styles";


function CheckboxField({
  meta,
  input,
  help,
  label,
  children,
  fieldProps = {},
  inputProps = {},
  ...restProps
}) {

  const id = generateFieldId("checkbox", meta, restProps, input);

  const { isInvalid, isValid } = returnFieldState(meta);

  return (
    <FormField
      className="form-field field-checkbox"
      isInvalid={isInvalid}
      {...fieldProps}>


      <FormLabel
        className="form-label"
        id={id}>
        {label}
      </FormLabel>


      <FormWrapper className="form-wrapper">

        <input
          id={id}
          readOnly={meta.submitting}
          css={[
            styles.input,
            isInvalid && styles.error,
            isValid && styles.success
          ]}
          {...inputProps}
          {...input}
          checked={input.checked || !!input.value}
          type="checkbox" />

        <label
          css={styles.label}
          htmlFor={id}>
          <span>{children}</span>
        </label>

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid} />


    </FormField>
  );
}


CheckboxField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  validate: PropTypes.array,
  label: PropTypes.string,
  children: PropTypes.node,
  help: PropTypes.string,
  defaultValue: PropTypes.bool
};



type ComponentPropsType = {
  name: string;
  id: string;
  defaultValue: Boolean;
  children: React.ReactNode | string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  fieldProps: Object;
  inputProps: React.InputHTMLAttributes;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  static Checkbox = CheckboxField;

  render() {
    return (
      <Field
        component={CheckboxField}
        {...this.props} />
    );
  }
}


// export default CheckboxField;
