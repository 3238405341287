import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { CommunicationPhoneLogResponseDto } from "../../@myk";


interface AccountState {
  key?: string,
  value?: string,
  communicationPreferences?: any,
  commPhoneLogs?: CommunicationPhoneLogResponseDto[]
}

const initialState: AccountState = {};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    getCommunicationPreferences(state, action: PayloadAction<object>) {
      state.communicationPreferences = action.payload;
    },
    updateCommunicationPreferences(state: AccountState, action: PayloadAction<any>) {
      state.key = action.payload.key;
      state.value = action.payload.value;
    },
    handleChangeCommunicationPrefences(state, action) {
      return state;
    },
    handleChangePhoneNumber(state) {
      return state;
    },
    getCommunicationPhoneLogsSuccess(state, action: PayloadAction<CommunicationPhoneLogResponseDto[]>) {
      state.commPhoneLogs = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default accountSlice.reducer;
export const {
  getCommunicationPreferences,
  updateCommunicationPreferences,
  handleChangeCommunicationPrefences,
  handleChangePhoneNumber,
  destroy
} = accountSlice.actions;
export const accountActions = accountSlice.actions;
