import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { LoanDto, CardResponseDto, AccountDto, CustomerCollectionCompanyHistoryDto } from "@myk";


interface DashboardState {
  activeLoanInProgress: boolean,
  customerAccounts: AccountDto[],
  cards: CardResponseDto[],
  arrangedDbtLoanIds: number[],
  lastLoanStatus?: string,
  allLoans?: LoanDto[],
  loanHistory?: any,
  activeLoans?: LoanDto[],
  showCancelTransferButton: boolean,
  overdueLoans?: LoanDto[],
  isLoading?: boolean,
  lastOverdueLoanCount: number;
  showAdditionalPhoneButton: boolean;
  collectionCompanyInfo?: CustomerCollectionCompanyHistoryDto;
}

const initialState: DashboardState = {
  activeLoanInProgress: true,
  customerAccounts: [],
  cards: [],
  arrangedDbtLoanIds: [],
  showCancelTransferButton: false,
  lastOverdueLoanCount: 0,
  showAdditionalPhoneButton: true
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setUserLastLoanStatus(state, action: PayloadAction<string>) {
      state.lastLoanStatus = action.payload;
    },
    getCustomerAccounts(state, action: PayloadAction<AccountDto[]>) {
      state.customerAccounts = action.payload;
    },
    getCards(state, action: PayloadAction<CardResponseDto[]>) {
      state.cards = action.payload;
    },
    getAllLoans(state, action: PayloadAction<LoanDto[]>) {
      state.allLoans = action.payload;
    },
    getLoanHistory(state, action) {
      state.loanHistory = action.payload;
    },
    getActiveLoans(state, action: PayloadAction<LoanDto[]>) {
      state.activeLoans = action.payload;
      state.activeLoanInProgress = false;
    },
    getOverdueLoans(state, action: PayloadAction<LoanDto[]>) {
      state.overdueLoans = action.payload;
    },
    setLastOverdueLoanCount(state, action: PayloadAction<number>) {
      state.lastOverdueLoanCount = action.payload;
    },
    getArrangedDbtLoan(state, action: PayloadAction<number[]>) {
      state.arrangedDbtLoanIds = action.payload;
    },
    updateCancelTransferStatus(state, action: PayloadAction<boolean>) {
      state.showCancelTransferButton = action.payload;
    },
    showAdditionalPhoneButton(state, action: PayloadAction<boolean>) {
      state.showAdditionalPhoneButton = action.payload;
    },
    getCollectionCompanyInfo(state, action: PayloadAction<CustomerCollectionCompanyHistoryDto>) {
      state.collectionCompanyInfo = action.payload;
    },
    cancelTransfer(state, action: PayloadAction<LoanDto>) {
      return state;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default dashboardSlice.reducer;
export const {
  setUserLastLoanStatus,
  getCustomerAccounts,
  getCards,
  getAllLoans,
  getLoanHistory,
  getActiveLoans,
  getOverdueLoans,
  setLastOverdueLoanCount,
  getArrangedDbtLoan,
  setIsLoading,
  destroy
} = dashboardSlice.actions;
export const dashboardActions = dashboardSlice.actions;
