import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface CreditHistoryState {
  loanHistory: Array<any>,
}

const initialState: CreditHistoryState = {
  loanHistory: []
};

const creditHistorySlice = createSlice({
  name: "creditHistory",
  initialState,
  reducers: {
    getLoanHistory(state, action: PayloadAction<any>) {
      state.loanHistory = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default creditHistorySlice.reducer;
export const {
  getLoanHistory,
  destroy
} = creditHistorySlice.actions;
export const creditHistoryActions = creditHistorySlice.actions;
