import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { CardResponseDto } from "@myk";


interface FinancialInfoState {
  cards?: CardResponseDto[],
  creditLimit?: any,
  hasSeenFtbNotification: boolean
}

const initialState: FinancialInfoState = {
  hasSeenFtbNotification: false
};

const financialInfoSlice = createSlice({
  name: "financialInfo",
  initialState,
  reducers: {
    getCards(state, action: PayloadAction<CardResponseDto[]>) {
      state.cards = action.payload;
    },
    getCreditLimitSuccess(state, action: PayloadAction<any>) {
      state.creditLimit = action.payload;
    },
    setHasSeenFtbNotification(state, action: PayloadAction<boolean>) {
      state.hasSeenFtbNotification = action.payload;
    },
    setCardAsActive(state, action: PayloadAction<string>) {
      return state;
    },
    destroy() {
      return initialState;
    }
  }
});


export default financialInfoSlice.reducer;
export const {
  getCards,
  getCreditLimitSuccess,
  setHasSeenFtbNotification,
  setCardAsActive,
  destroy
} = financialInfoSlice.actions;
export const financialInfoActions = financialInfoSlice.actions;
