import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface BankCredentialsState {
  modalClosable: boolean,
  credentialId?: number,
  customerCredentialList?: any,
  activeProvider?: "unnax" | "tink",
  widgetUrl?: string
}

const initialState: BankCredentialsState = {
  modalClosable: false
};

const bankCredentialsSlice = createSlice({
  name: "bankCredentials",
  initialState,
  reducers: {
    changeModalClosableState(state, action: PayloadAction<boolean>) {
      state.modalClosable = action.payload;
    },
    chooseCredential(state, action) {
      state.credentialId = action.payload;
    },
    passCustomerCredentialList(state, action) {
      state.customerCredentialList = action.payload;
    },
    getActiveProvider(state, action) {
      state.activeProvider = action.payload;
    },
    initializeWidgetModal(state, action) {
      state.modalClosable = false;
    },
    passWidgetUrl(state, action: PayloadAction<string>) {
      state.widgetUrl = action.payload;
    },
    sendWidgetEvents: {
      reducer: (state, action: PayloadAction<any>) => {
        return state;
      },
      prepare: (widgetPayload, widgetProvider) => {
        return {
          payload: {
            widgetPayload,
            widgetProvider
          }
        };
      }
    },
    destroy() {
      return initialState;
    }
  }
});


export default bankCredentialsSlice.reducer;
export const {
  changeModalClosableState,
  chooseCredential,
  passCustomerCredentialList,
  getActiveProvider,
  initializeWidgetModal,
  passWidgetUrl,
  sendWidgetEvents,
  destroy
} = bankCredentialsSlice.actions;
export const bankCredentialsActions = bankCredentialsSlice.actions;
