import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface DirectBankTransferState {
  modalClosable?: boolean,
  widgetUrl?: string
}

const initialState: DirectBankTransferState = {
};

const directBankTransferSlice = createSlice({
  name: "directBankTransfer",
  initialState,
  reducers: {
    changeModalClosableState(state, action: PayloadAction<boolean>) {
      state.modalClosable = action.payload;
    },
    passWidgetUrl(state, action: PayloadAction<string>) {
      state.widgetUrl = action.payload;
    },
    paymentHasSucceeded(state, action) {
      return state;
    },
    sendWidgetEvents(state, action) {
      return state;
    },
    initWidget: {
      reducer: (state, action: PayloadAction<any>) => state,
      prepare: (loanId, amount) => {
        return {
          payload: {
            loanId,
            amount
          }
        };
      }
    },
    destroy() {
      return initialState;
    }
  }
});


export default directBankTransferSlice.reducer;
export const {
  changeModalClosableState,
  paymentHasSucceeded,
  sendWidgetEvents,
  passWidgetUrl,
  initWidget,
  destroy
} = directBankTransferSlice.actions;
export const directBankTransferActions = directBankTransferSlice.actions;
