import _ from "lodash";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const transition = `transition: ${[
  `color ${VARS.animation.duration} ${VARS.animation.timing}`,
  `border-color ${VARS.animation.duration} ${VARS.animation.timing}`,
  `background-color ${VARS.animation.duration} ${VARS.animation.timing}`
].join()}`;

export const base = css`
display: flex;
margin: 0;
outline: 0;
height: 56px;
flex-grow: 1;
border-radius: 1px;
line-height: 40px;
background-color: white;
border: 1px solid ${COLORS.paleGreyFour};
${transition};

box-shadow: 0 0 0 0 transparent inset;
-webkit-appearance: none;
tap-highlight-color: rgba(255,255,255,0);
font-size: 16px;

max-width: 100%;
`;

export const hasFocused = css`
border-color: ${COLORS.primary} !important;
`;

export const input = css`
  padding: 26px 12px 8px;
  display:flex;
  border: none;
  outline: none;
  max-width: 100%;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  color: ${COLORS.charcoalGrey};
  flex-grow: 1;
  line-height: 40px;
  & + button {
    svg path {
      fill: ${COLORS.charcoalGrey};
      fill: ${COLORS.paleGreyFour};
    }
  }
  &:focus:hover,
  &:focus,
  &:hover {
    color: #000;
    & + button svg path {
      fill: ${COLORS.charcoalGrey};
    }
  }
  &:focus:hover,
  &:focus {
    ${""/* background-color: #fff; */}
      border-color: ${COLORS.primary};
    }
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled] {
    opacity: 0.4 !important;
    cursor: not-allowed;
  }
  ${"" /* &[name="pin"] {
    font-family: "Bullets" !important;
    font-size: 32px;
    letter-spacing: 2px;
  } */}


  &[type='date'],
  &[type='time'] {
    -webkit-appearance: none;
}

`;

export const phoneInput = css`
  padding: 26px 8px 12px 3px;
`;

export const error = css`
  &:not([disabled]) {
    ${""/* background-color: #fff2f2; */}
    border-color: ${VARS.form.errorColor};
    &[disabled],
    &:hover,
    &:focus {
      ${""/* background-color: ${HELPERS.color.lightenDarken("#fff2f2", 5)}; */}
      border-color: ${VARS.form.errorColor};
    }
  }
  `;


export const success = css`
  &:not([disabled]) {
    ${""/* background-color: #e5fdf9; */}
    border-color: ${VARS.form.successColor};
    &[disabled],
    &:hover,
    &:focus {
      ${""/* background-color: ${HELPERS.color.lightenDarken("#e5fdf9", 5)}; */}
      border-color: ${VARS.form.successColor};
    }
  }
  `;

export const phoneFormatLabel = css`
  padding-top: 26px;
  padding-bottom: 12px;
  padding-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
