// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { css } from "@emotion/react";
import { useSpring, animated } from "react-spring";

import { VARS } from "styles-js";



const errorStyles = css`
  font-size: 12px;
  line-height: 1.6;
  text-align: left;
  color: ${VARS.form.errorColor};
  svg path {
    fill: ${VARS.form.errorColor};
  }
`;

interface ComponentProps {
  isVisible: boolean,
  errorMessage: string
}

export default function FormValidation({ isVisible, errorMessage }: ComponentProps) {

  const styles = {
    from: {
      opacity: 0,
      marginTop: 0
    },
    to: {
      opacity: isVisible ? 1 : 0,
      marginTop: isVisible ? 5 : 0
    }
  };

  const styleProps = useSpring(styles);

  return (

    <animated.div
      css={errorStyles}
      className="form-messages"
      style={styleProps}>
      {isVisible && (
        <>
          {/* <Icon name="exclamation-circle" /> */} {errorMessage}
        </>
      )}
    </animated.div>

  );
}

FormValidation.propTypes = {
  isVisible: PropTypes.bool,
  errorMessage: PropTypes.string
};
