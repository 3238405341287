import _ from "lodash";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import unchecked from "../../assets/svg/checkboxes/unchecked.svg";
import checked from "../../assets/svg/checkboxes/checked.svg";
import uncheckedDisabled from "../../assets/svg/checkboxes/unchecked-disabled.svg";
import checkedDisabled from "../../assets/svg/checkboxes/checked-disabled.svg";


export const input = css`

  display: block;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  &[disabled] {
    & + label {
      cursor: disabled;
      cursor: not-allowed;
      &:before {
      }
    }
  }

  &:not(:checked) + label:before {
    background-image: url(${unchecked});
  }
  &:checked:not([disabled]) + label:hover:before,
  &:not(:checked):not([disabled]) + label:hover:before {
    box-shadow: 0 0 1px 1px #ccc;
    border-radius:2px;
  }
  &[disabled]:not(:checked) + label:before,
  &[disabled]:not(:checked):hover + label:before {
    background-image: url(${unchecked});
  }
  &:checked + label:before {
    background-image: url(${checked});
  }
  &[disabled]+ label:before {
    background-image: url(${uncheckedDisabled});
  }
  &[disabled]:checked + label:before {
    background-image: url(${checkedDisabled});
  }

  &[disabled] + label {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;



export const label = css`
  user-select: none;
  line-height: 1.5;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  :before {
    content: "";
    width: 24px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
  }
`;


export const success = css`
`;
export const error = css`
`;
