import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface LoginState {
  canRequestNewPin: boolean,
  otpSendingInProgress?: boolean,
  customerBasicInfo?: any
}

const initialState: LoginState = {
  canRequestNewPin: false
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    requestNewPin(state, action) {
      return state;
    },
    toggleRequestingNewPin: {
      reducer: (state, action: PayloadAction<any>) => {
        state.canRequestNewPin = action.payload.canRequestNewPin;
        state.otpSendingInProgress = action.payload.otpSendingInProgress;
      },
      prepare: (canRequestNewPin: boolean, otpSendingInProgress: boolean) => {
        return {
          payload: {
            canRequestNewPin,
            otpSendingInProgress
          }
        };
      }
    },
    passCustomerBasicInfo(state, action) {
      state.customerBasicInfo =  action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default loginSlice.reducer;
export const {
  requestNewPin,
  toggleRequestingNewPin,
  passCustomerBasicInfo,
  destroy
} = loginSlice.actions;
export const loginActions = loginSlice.actions;
