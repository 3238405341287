import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface RolloverState {
  agreementType?: string,
  termsAndConditions?: string,
  loanExplanation?: string,
  newLoanSummary?: any,
  isNewLoanInProgress?: boolean,
  minimumRollOverAmount?: number,
  rolloverDates?: string[],
  currentTime?: string,
  loan?: any,
  cards?: any[],
}

const initialState: RolloverState = {
};

const rolloverSlice = createSlice({
  name: "rollover",
  initialState,
  reducers: {
    getAgreementsStart(state, action) {
      state.agreementType = action.payload;
    },
    getAgreementsSuccess(state, action) {
      state.termsAndConditions = action.payload.termsAndConditions;
      state.loanExplanation = action.payload.loanExplanation;
    },
    getNewLoanSummaryStart(state, action) {
      state.newLoanSummary = undefined;
      state.isNewLoanInProgress = true;
    },
    getNewLoanSummarySuccess(state, action) {
      state.newLoanSummary = action.payload;
      state.isNewLoanInProgress = false;
    },
    passMinimumRolloverAmount(state, action) {
      state.minimumRollOverAmount = action.payload;
    },
    getRolloverDates(state, action) {
      state.rolloverDates = action.payload;
    },
    passCurrentTime(state, action) {
      state.currentTime = action.payload;
    },
    getLoan(state, action) {
      state.loan = action.payload;
    },
    getCards(state, action) {
      state.cards = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default rolloverSlice.reducer;
export const {
  getAgreementsStart,
  getAgreementsSuccess,
  getNewLoanSummaryStart,
  getNewLoanSummarySuccess,
  passMinimumRolloverAmount,
  getRolloverDates,
  passCurrentTime,
  getLoan,
  getCards,
  destroy
} = rolloverSlice.actions;
export const rolloverActions = rolloverSlice.actions;
