// @ts-nocheck
import _ from "lodash";

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { reducer as reduxformReducer } from "redux-form/dist/redux-form";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";

import { alertReducer } from "kit/alert";

import history from "../utils/history";

import sharedReducer from "../containers/Shared/Shared.slice";
import appReducer from "../containers/App/App.slice";
import confirmReducer from "../containers/Confirm/Confirm.slice";
import accountReducer from "../containers/Account/Account.slice";
import addressDetailsReducer from "../containers/AddressDetails/AddressDetails.slice";
import bankCredentialsReducer from "../containers/BankCredentials/BankCredentials.slice";
import callMeReducer from "../containers/CallMe/CallMe.slice";
import creditHistoryReducer from "../containers/CreditHistory/CreditHistory.slice";
import creditSummaryReducer from "../containers/CreditSummary/CreditSummary.slice";
import dashboardReducer from "../containers/Dashboard/Dashboard.slice";
import directBankTransferReducer from "../containers/DirectBankTransfer/DirectBankTransfer.slice";
import documentsReducer from "../containers/Documents/Documents.slice";
import financialInfoReducer from "../containers/FinancialInfo/FinancialInfo.slice";
import handoverReducer from "../containers/Handover/Handover.slice";
import homeReducer from "../containers/Home/Home.slice";
import loanApplyReducer from "../containers/LoanApply/LoanApply.slice";
import loanFlowReducer from "../containers/LoanFlow/LoanFlow.slice";
import loanRequestReducer from "../containers/LoanRequest/LoanRequest.slice";
import loginReducer from "../containers/Login/Login.slice";
import otpReducer from "../containers/Otp/Otp.slice";
import paymentReducer from "../containers/Payment/Payment.slice";
import paymentReasonReducer from "../containers/PaymentReason/PaymentReason.slice";
import registerReducer from "../containers/Register/Register.slice";
import restructureReducer from "../containers/Restructure/Restructure.slice";
import rolloverReducer from "../containers/Rollover/Rollover.slice";
import staticPagesReducer from "../containers/StaticPages/StaticPages.slice";
import threeDSecureReducer from "../containers/ThreeDSecure/ThreeDSecure.slice";

import blacklistedActions from "./blacklistedActions";
import reduxLoggerMiddleware from "./reduxLoggerMiddleware";
import reduxProfilerMiddleware from "./reduxProfilerMiddleware";

export const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => console.log(error, errorInfo)
});
const historyRouterMiddleware = routerMiddleware(history);

const rootReducer = combineReducers({
  app: appReducer,
  shared: sharedReducer,
  confirm: confirmReducer,
  alerts: alertReducer,
  account: accountReducer,
  addressDetails: addressDetailsReducer,
  bankCredentials: bankCredentialsReducer,
  callMe: callMeReducer,
  creditHistory: creditHistoryReducer,
  creditSummary: creditSummaryReducer,
  dashboard: dashboardReducer,
  directBankTransfer: directBankTransferReducer,
  documents: documentsReducer,
  financialInfo: financialInfoReducer,
  handover: handoverReducer,
  home: homeReducer,
  loanApply: loanApplyReducer,
  loanFlow: loanFlowReducer,
  loanRequest: loanRequestReducer,
  login: loginReducer,
  otp: otpReducer,
  payment: paymentReducer,
  paymentReason: paymentReasonReducer,
  register: registerReducer,
  restructure: restructureReducer,
  rollover: rolloverReducer,
  staticPages: staticPagesReducer,
  threeDSecure: threeDSecureReducer,
  router: connectRouter(history),
  form: reduxformReducer
});


const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const middleware = getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: blacklistedActions
      }
    }).concat(
      sagaMiddleware,
      historyRouterMiddleware
    );

    if (import.meta.env.DEV) {
      middleware.push(reduxProfilerMiddleware);
      middleware.push(reduxLoggerMiddleware);
    }

    return middleware;
  },
  // Only enable redux dev tools for development environment
  devTools: !(import.meta.env.PROD && import.meta.env.MODE === "production")
});

store.runSaga = sagaMiddleware.run;
store.injectedSagas = {};

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
