import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface StaticPagesState {
  termsAndConditions: any,
  ekomiReviews: any[],
  amount?: number,
  paymentDates?: string[]
}

const initialState: StaticPagesState = {
  ekomiReviews: [],
  termsAndConditions: {}
};

const staticPagesSlice = createSlice({
  name: "staticPages",
  initialState,
  reducers: {
    getTermsAndConditionsSuccess(state, action: PayloadAction<any>) {
      state.termsAndConditions = action.payload;
    },
    fetchEkomiReviewsRequest(state, action: PayloadAction<any>) {
      return state;
    },
    fetchEkomiReviewsSuccess(state, action: PayloadAction<any>) {
      state.ekomiReviews = action.payload;
    },
    fetchEkomiReviewsFailure(state, action: PayloadAction<any>) {
      state.ekomiReviews = action.payload;
    },
    calculateLoanRequest(state, action: PayloadAction<any>) {
      return state;
    },
    calculateLoanSuccess(state, action: PayloadAction<any>) {
      state.amount = action.payload.amount;
      state.paymentDates = action.payload.paymentDates;
    },
    destroy() {
      return initialState;
    }
  }
});


export default staticPagesSlice.reducer;
export const {
  getTermsAndConditionsSuccess,
  fetchEkomiReviewsRequest,
  fetchEkomiReviewsSuccess,
  fetchEkomiReviewsFailure,
  calculateLoanRequest,
  calculateLoanSuccess,
  destroy
} = staticPagesSlice.actions;
export const staticPagesActions = staticPagesSlice.actions;
