import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface LoanApplyState {
}

const initialState: LoanApplyState = {
};

const loanApplySlice = createSlice({
  name: "loanApply",
  initialState,
  reducers: {
    destroy() {
      return initialState;
    }
  }
});


export default loanApplySlice.reducer;
export const {
  destroy
} = loanApplySlice.actions;
export const loanApplyActions = loanApplySlice.actions;
