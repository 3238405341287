// @ts-nocheck

import React, { lazy, Suspense } from "react";


const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "date-picker-field" */"./DatePicker.component")
);


const DatePicker = props =>
  <Suspense
    fallback={<div style={{ minHeight: 200 }} />}>
    <LazyComponent {...props} />
  </Suspense>;

export default DatePicker;
