// @ts-nocheck


import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { connect } from "react-redux";

import hoistNonReactStatics from "hoist-non-react-statics";
import { onlyUpdateForKeys, compose, wrapDisplayName, setDisplayName } from "recompose";
import { reset, startSubmit, stopSubmit, submit, change, SubmissionError } from "redux-form/dist/redux-form";

import store from "../store/configureStore";

import { createFormAction } from "./FormSagaProvider";


const addFunctionalities = (FormComponent, formActions) => {
  return {
    ...formActions,
    RESET: `${FormComponent.PREFIX}_RESET`,
    reset: () => ({
      type: `${FormComponent.PREFIX}_RESET`,
      formName: FormComponent.NAME
    }),
    getValues: () => {
      const storeObj = store.getState();
      const formvalues = _.get(storeObj.form, [
        FormComponent.NAME,
        "values"
      ], {});
      return formvalues;
    },
    clear: () => reset(FormComponent.NAME),
    startSubmit: () => startSubmit(FormComponent.NAME),
    submit: () => submit(FormComponent.NAME),
    stopSubmit: (formErrors) => stopSubmit(FormComponent.NAME, formErrors),
    change: (fieldName, fieldValue) => change(FormComponent.NAME, fieldName, fieldValue)
    // success: () => {
    //   dispatch(stopSubmit(FormComponent.NAME));
    //   return FormComponent.ACTIONS.success();
    // },
    // failure: () => {
    //   dispatch(stopSubmit(FormComponent.NAME));
    //   return FormComponent.ACTIONS.success();
    // }
  };
};


/* eslint-disable-next-line */
const mapStateToProps = formName =>
  state => ({
    formState: state.form[formName]
  });

export type FormActionType = {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
  RESET: string;
  request: Function;
  success: Function;
  failure: Function;
  getValues: Function;
  reset: Function;
  clear: Function;
  change: Function;
  startSubmit: Function;
  stopSubmit: Function;
  submit: Function;
};

// const addFormResetFunctionality = (FormComponent) => ({
//   ...FormComponent.ACTIONS,
//   RESET: `${FormComponent.PREFIX}_RESET`,
//   reset: () => reset(FormComponent.NAME)
// });

const FormProvider = (TargetFormComponent) => {

  const NAME = TargetFormComponent.NAME;
  const PREFIX = TargetFormComponent.PREFIX;
  const payloadCreator = TargetFormComponent.PAYLOAD_CREATOR;

  const formAction = createFormAction(TargetFormComponent.PREFIX, undefined, payloadCreator);

  class StateProvider extends PureComponent {

    static NAME = NAME;
    static ACTIONS: FormActionType = addFunctionalities(TargetFormComponent, formAction);
    // static ACTIONS: FormActionType = TargetFormComponent.ACTIONS;
    static PREFIX = PREFIX;

    handleReset = () => {
      // this.context.store.dispatch(reset(NAME));
      this.context.store.dispatch(StateProvider.ACTIONS.reset());
    };
    handleClear = () => {
      // this.context.store.dispatch(reset(NAME));
      this.context.store.dispatch(StateProvider.ACTIONS.clear());
    };

    render() {
      const { formState = {} } = this.props;
      const registeredFields = _.mapValues(formState.registeredFields, "name");
      const formErrors = Object.assign(
        {},
        _.zipObject(registeredFields, []),
        formState.syncErrors
      );
      const formMeta = Object.assign(
        {},
        _.zipObject(registeredFields, []),
        formState.fields
      );
      const formValues = Object.assign(
        {},
        _.zipObject(registeredFields, []),
        formState.values
      );
      return React.createElement(TargetFormComponent, {
        ...this.props,
        formErrors,
        formMeta,
        formValues,
        handleReset: this.handleReset,
        handleClear: this.handleClear,
        formAction
        // handleSagaSubmit: this.props
      });
    }
  }

  StateProvider.propTypes = {
    formState: PropTypes.object
  };

  StateProvider.contextTypes = {
    ...TargetFormComponent.contextTypes,
    store: PropTypes.object
  };
  hoistNonReactStatics(StateProvider, TargetFormComponent, {
    ACTIONS: true
  });

  return compose(
    /* eslint-disable-next-line */
    connect(mapStateToProps(NAME)),
    // onlyUpdateForKeys([ "state" ]),
    setDisplayName("FormProvider")
  )(StateProvider);

};

export default FormProvider;
