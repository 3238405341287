// @ts-nocheck

import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";


const styles = css`
  margin: 5px 0 0;
  display: block;
  font-size: 12px;
  line-height: 1.6;
  color: #acb1c0;
  display: flex;
  flex-direction: row;
`;


export default function FormHelp({ children, ...restProps }) {

  return (
    !!children && (
      <p css={styles} {...restProps}>
        {children}
      </p>
    )
  );
}


FormHelp.propTypes = {
  children: PropTypes.node
};
