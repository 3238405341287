// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment, useRef, useState } from "react";

import cx from "classnames";
import { Field, change, touch, type BaseFieldProps } from "redux-form/dist/redux-form";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import Icon from "../ui/Icon/Icon";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState } from "./formMetaHelpers";


import * as styles from "./FileInputField.styles";


const PREVIEW_EXTENSIONS = [
  "jpg",
  "jpeg",
  "gif",
  "png",
  "svg"
];

function FileInputField(props) {

  const [ state, setState ] = useState({
    errorMessage: "",
    selectedFile: "",
    imgUri: ""
  });

  const fileInputRef = useRef();

  const showImagePreview = (file) => {
    try {
      const extension = file.name.match(/\.([a-zA-Z0-9]+$)/)[1];
      const isImage = _.includes(PREVIEW_EXTENSIONS, extension);
      if (file && isImage) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setState(val => ({
            ...val,
            imgUri: event.target.result
          }));
        };
        return reader.readAsDataURL(file);
      }
      setState(val => ({
        ...val,
        imgUri: ""
      }));
    }
    catch (err) {
      window["console"]["log"]("err: ", err); // eslint-disable-line
    }
  };

  const clearField = (obj = {}) => {
    // this.props.meta.dispatch(change(this.props.meta.form, this.props.input.name, ""));
    props.input.onChange("");
    fileInputRef.current.value = "";
    setState(val => ({
      ...val,
      selectedFile: "",
      imgUri: "",
      errorMessage: "",
      ...obj
    }));
    _.defer(_.invoke, props, "handleFileClear");
  };

  const handleFileChange = (event) => {
    props.input.onBlur();
    const inputFiles = event.target.files; // FileList object
    if (!inputFiles.length) {
      clearField();
      return;
    }
    const files = _.map(inputFiles, item => ({
      name: item.name,
      type: item.type,
      size: item.size,
      modifiedDate: item.modifiedDate
    }));
    const fileName = _.map(files, "name").join(", ");
    const validationError = fileValidation(files);
    if (validationError) {
      clearField({
        errorMessage: validationError
      });
      return;
    }
    setState(val => ({
      ...val,
      errorMessage: "",
      selectedFile: !validationError && fileName
    }));
    props.input.onChange(fileName);
    // this.props.meta.dispatch(change(this.props.meta.form, this.props.input.name, fileName));
    showImagePreview(inputFiles[0]);
    _.defer(_.invoke, props, "handleFileSelect", event.target.files);
  };

  const handleInputClick = () => {
    props.meta.dispatch(touch(props.meta.form, props.input.name, true));
    fileInputRef.current.click();
  };

  const fileValidation = (files) => {
    const {
      maxFileSize = 10 * 1000 * 1000
    } = props;
    if (_.isEmpty(files)) {
      return null;
    }
    const errMessage = _.map(files, ({ name, size, type }) => {
      const isFileTypeInvalid = props.accept && !_.includes(props.accept, _.toLower(_.last(_.split(name, "."))));
      if (isFileTypeInvalid) {
        return "Invalid file type";
      }
      if (maxFileSize < size) {
        return `File size exceeds over ${maxFileSize / 1000 / 1000}mb`;
      }
      return null;
    });
    return _.head(_.compact(errMessage));
  };

  const handleClickDeleteIcon = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    clearField();
  };

  const defaultInputAction = {
    labelPosition: "right",
    icon: "paperclip",
    type: "button",
    content: "Choose File",
    onClick: handleInputClick
  };

  const {
    meta,
    input,
    help,
    label,
    children,
    placeholder,
    accept,
    preview = true,
    multiple = false,
    renderFilePreview,
    fieldProps = {},
    inputProps = {},
    imageProps = {},
    ...restProps
  } = props;

  const id = generateFieldId("input", meta, restProps, input);
  const { isInvalid, isValid } = returnFieldState(meta);

  return (
    <FormField
      className="form-field field-file"
      {...fieldProps}>


      <FormWrapper
        isInvalid={isInvalid}
        isValid={isValid}>
        <label css={styles.label} id={id} onClick={handleInputClick}>
          <div css={styles.fileInputPreview}>
            {!!state.selectedFile && (
              <img width="100" src={state.imgUri} alt="Image Preview" />
            )}
            {!state.selectedFile && (
              <div css={styles.imgPlaceholder}>
                <Icon size="24" name="myk camera" />
              </div>
            )}
            {!!state.imgUri && (
              <Icon size="12" color="green" name="myk check" />
            )}
            {/* <Button outline theme="battleshipGrey" onClick={() => handleDeleteFile()}><Icon icon={trash} /></Button> */}
          </div>
          <div css={styles.labelWrapper}>
            {!state.selectedFile && (
              <>
                <span>{label}</span>
              </>
            )}
            {state.selectedFile && (
              <p>{state.selectedFile}</p>
            )}
          </div>
          {state.selectedFile && (
            <Icon
              onClick={handleClickDeleteIcon}
              css={styles.deleteIcon}
              size="20"
              name="myk delete" />
          )}
        </label>
        <input
          autoComplete="off"
          type="text"
          name={`${input.name}-visual`}
          placeholder={placeholder}
          css={STYLES.visuallyHidden}
          {...inputProps}
          action={Object.assign({}, defaultInputAction, inputProps.action)}
          value={state.selectedFile}
          onChange={handleFileChange}
          onClick={handleInputClick} />

        <input
          ref={fileInputRef}
          css={STYLES.visuallyHidden}
          accept={accept}
          multiple={multiple}
          id={`${id}-hidden`}
          type="file"
          name={`${input.name}-hidden`}
          onChange={handleFileChange} />

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid && meta.submitFailed} />


    </FormField>
  );
}

FileInputField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  maxFileSize: PropTypes.number,
  multiple: PropTypes.bool,
  preview: PropTypes.bool,
  placeholder: PropTypes.string,
  accept: PropTypes.string,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  inputLeft: PropTypes.object,
  inputRight: PropTypes.object,
  validate: PropTypes.array,
  label: PropTypes.any,
  children: PropTypes.any,
  help: PropTypes.string,
  imageProps: PropTypes.object,
  renderFilePreview: PropTypes.func,
  handleFileSelect: PropTypes.func
};



type ComponentPropsType = {
  name: string;
  id: string;
  placeholder: string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  fieldProps: Object;
  inputProps: React.InputHTMLAttributes;
  maxFileSize: number;
  multiple: Boolean;
  preview: Boolean;
  accept: string[];
  imageProps: React.ImgHTMLAttributes;
  handleFileSelect: Function;
  renderFilePreview: Function;
  handleFileClear: Function;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {
  render() {
    return (
      <Field
        component={FileInputField}
        {...this.props} />
    );
  }
}


// export default FileInputField;
