import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import DateService from "../../core/DateService";


interface HandoverState {
  canRequestNewPin: boolean,
  amount: number,
  paymentDay: number,
  paymentDates: string[],
  viewedInstallment: any,
  otpSendingInProgress?: boolean,
  affiliateDetails?: any,
  calculationInProgress?: boolean
}

const now = (new Date()).toISOString();

export const initialState: HandoverState = {
  canRequestNewPin: false,
  amount: 400,
  paymentDay: 30,
  paymentDates: [ DateService.add(now, 30, "day", "ISO") ],
  viewedInstallment: {}
};

const handoverSlice = createSlice({
  name: "handover",
  initialState,
  reducers: {
    requestNewPin(state) {
      return state;
    },
    toggleRequestingNewPin: {
      reducer: (state, action: PayloadAction<any>) => {
        state.canRequestNewPin = action.payload.canRequestNewPin;
        state.otpSendingInProgress = action.payload.otpSendingInProgress;
      },
      prepare: (canRequestNewPin: boolean, otpSendingInProgress: boolean) => {
        return {
          payload: {
            canRequestNewPin,
            otpSendingInProgress
          }
        };
      }
    },
    passAffiliateDetails(state, action) {
      state.affiliateDetails = action.payload;
    },
    calculateLoanRequest: {
      reducer: (state, action: PayloadAction<any>) => {
        state.amount = action.payload.amount;
        state.paymentDay = action.payload.paymentDay;
        state.paymentDates = action.payload.paymentDates;
        state.calculationInProgress = true;
      },
      prepare: (amount, paymentDates, paymentDay) => {
        return {
          payload: {
            amount,
            paymentDates,
            paymentDay
          }
        };
      }
    },
    calculateLoanSuccess(state, action) {
      state.viewedInstallment = action.payload;
      state.calculationInProgress = false;
    },
    calculateLoanFailure(state, action) {
      state.calculationInProgress = false;
    },
    destroy() {
      return initialState;
    }
  }
});


export default handoverSlice.reducer;
export const {
  requestNewPin,
  toggleRequestingNewPin,
  passAffiliateDetails,
  calculateLoanRequest,
  calculateLoanSuccess,
  calculateLoanFailure,
  destroy
} = handoverSlice.actions;
export const handoverActions = handoverSlice.actions;
