import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { LoanArrangementRequestDto } from "@myk";


interface PaymentReasonState {
  requestInProgress: boolean,
  holidays: string[]
}

const initialState: PaymentReasonState = {
  requestInProgress: false,
  holidays: []
};

const paymentReasonSlice = createSlice({
  name: "paymentReason",
  initialState,
  reducers: {
    arrangeLoanRequest(state) {
      state.requestInProgress = true;
    },
    arrangeLoanSuccess(state) {
      state.requestInProgress = false;
    },
    arrangeLoanFailure(state) {
      state.requestInProgress = false;
    },
    refundCallRequest(state) {
      state.requestInProgress = true;
    },
    refundCallSuccess(state) {
      state.requestInProgress = false;
    },
    refundCallFailure(state) {
      state.requestInProgress = false;
    },
    getHolidaysList(state, action) {
      state.holidays = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default paymentReasonSlice.reducer;
export const {
  arrangeLoanRequest,
  arrangeLoanSuccess,
  arrangeLoanFailure,
  refundCallRequest,
  refundCallSuccess,
  refundCallFailure,
  getHolidaysList,
  destroy
} = paymentReasonSlice.actions;
export const paymentReasonActions = paymentReasonSlice.actions;
