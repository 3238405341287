import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface ConfirmState {
  active: boolean,
  id?: string,
  subType?: string,
  title?: string,
  icon?: string,
  content?: string,
  buttonNo?: string,
  buttonYes?: string,
  description?: string,
  showButtonNo?: boolean,
  titleProps?: any,
  contentProps?: any,
  iconProps?: any,
  actionsProps?: any,
  descriptionProps?: any
}

const initialState: ConfirmState = {
  active: false
};

const confirmSlice = createSlice({
  name: "confirm",
  initialState,
  reducers: {
    yes() {
      return initialState;
    },
    no() {
      return initialState;
    },
    cancel() {
      return initialState;
    },
    hide() {
      return initialState;
    },
    show(state, action) {
      return {
        ...state,
        id: action.payload.id,
        active: true,
        subType: action.payload.subType,
        title: action.payload.title,
        icon: action.payload.icon,
        content: action.payload.content,
        buttonNo: action.payload.buttonNo,
        buttonYes: action.payload.buttonYes,
        description: action.payload.description,
        showButtonNo: action.payload.showButtonNo,
        titleProps: action.payload.titleProps,
        contentProps: action.payload.contentProps,
        iconProps: action.payload.iconProps,
        actionsProps: action.payload.actionsProps,
        descriptionProps: action.payload.descriptionProps,
        showCloseIcon: action.payload.showCloseIcon
      };
    },
    destroy() {
      return initialState;
    }
  }
});


export default confirmSlice.reducer;
export const {
  yes,
  no,
  show,
  hide,
  destroy
} = confirmSlice.actions;
export const confirmActions = confirmSlice.actions;
