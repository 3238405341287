// @ts-nocheck

import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";

import { COLORS } from "styles-js";


const fieldStyle = css`
  clear: both;
  margin: 0 0 10px;
  flex-grow: 1;
  > label {
      flex: 1 1 100%;
      font-size: 16px;
      color: ${COLORS.charcoalGrey};
    }
  &.field-radio {
    > label {
      font-size: 11px;
      color: ${COLORS.coolGrey};
      line-height: 11px;
      margin-bottom: 6px;
      display: block;
    }
  }
`;

export default function FormField({ isInvalid, children, ...restProps }) {

  return (
    <div css={fieldStyle} {...restProps}>
      {children}
    </div>
  );
}


FormField.propTypes = {
  isInvalid: PropTypes.bool,
  children: PropTypes.node
};
