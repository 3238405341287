import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface ThreeDSecureState {
  modalClosable: boolean,
  formData?: any,
  formPostUrl?: string,
  transactionId?: string
}

const initialState: ThreeDSecureState = {
  modalClosable: true
};

const threeDSecureSlice = createSlice({
  name: "threeDSecure",
  initialState,
  reducers: {
    changeModalClosableState(state, action: PayloadAction<boolean>) {
      state.modalClosable = action.payload;
    },
    openIframeAndPostFormData: {
      reducer: (state, action: PayloadAction<any>) => {
        state.formData = action.payload.formData;
        state.formPostUrl = action.payload.formPostUrl;
        state.transactionId = action.payload.transactionId;
      },
      prepare: (formData: any, formPostUrl: string, transactionId: string) => {
        return {
          payload: {
            formData,
            formPostUrl,
            transactionId
          }
        };
      }
    },
    cardStatusCheckCompleted(state, action: PayloadAction<any>) {
      return state;
    },
    callbackRecieved(state, action: PayloadAction<any>) {
      return state;
    },
    flowFailed(state, action: PayloadAction<any>) {
      return state;
    },
    flowSucceeded(state, action: PayloadAction<any>) {
      return state;
    },
    destroy() {
      return initialState;
    }
  }
});


export default threeDSecureSlice.reducer;
export const {
  changeModalClosableState,
  openIframeAndPostFormData,
  cardStatusCheckCompleted,
  callbackRecieved,
  flowFailed,
  flowSucceeded,
  destroy
} = threeDSecureSlice.actions;
export const threeDSecureActions = threeDSecureSlice.actions;
