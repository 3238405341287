// @ts-nocheck

/* eslint-disable react/prop-types */
import _ from "lodash";

import DateService from "core/DateService";


class Validation {
  constructor() {
    this.successReturnValue = undefined;
    this.templateInterpolation = /\{([a-zA-Z0-9_-]+)\}/g;
    _.bindAll(this, [
      "setLanguageObject",
      "setSuccessReturnValue",
      "returnErrorMessage",
      "returnValidationResult",
      "required",
      "pattern",
      "min",
      "max",
      "minLength",
      "maxLength",
      "email",
      "url",
      "number",
      "sameAs",
      "string",
      "dateValid",
      "dateRange",
      "setTemplateInterpolation",
      "translator"
    ]);
    this.lang = {};
  }

  setTemplateInterpolation = (regex = this.templateInterpolation) => {
    this.templateInterpolation = regex;
  };

  translator = (str: string = "", contentVariables?: Object) => {
    if (_.isNil(contentVariables)) {
      return str;
    }
    _.templateSettings.interpolate = this.templateInterpolation;
    const compiled = _.template(str);
    return compiled(contentVariables);
  };

  setLanguageObject(langObject: Object) {
    this.lang = langObject;
  }

  setSuccessReturnValue(value: any) {
    this.successReturnValue = value;
  }

  returnErrorMessage(langKey: string, customMsg?: string, translationVariables?: Object = {}) {
    const msg = _.defaultTo(
      customMsg,
      _.get(this.lang, langKey, "default field error")
    );
    return this.translator(msg, translationVariables);
  }

  returnValidationResult(isValid: boolean, ...errorMessageParams) {
    const errorMessage = this.returnErrorMessage(...errorMessageParams);
    return isValid ? this.successReturnValue : errorMessage;
  }

  required(customMsg) {
    return (value?: string | Array | boolean) => {
      const isValid = !(
        _.isNil(value) ||
        (_.isArray(value) && _.isEmpty(value)) ||
        (_.isBoolean(value) && !value) ||
        (_.isString(value) && _.isEmpty(value))
      );
      return this.returnValidationResult(isValid, "required", customMsg);
    };
  }

  pattern(pattern: Object, customMsg?: string) {
    return (value?: string) => {
      const isValid = pattern.test(value);
      return this.returnValidationResult(isValid, "pattern", customMsg);
    };
  }

  min(min: number, customMsg?: string) {
    return (value?: string) => {
      const isValid = Number(value) >= Number(min);
      return this.returnValidationResult(isValid, "min", customMsg, { min });
    };
  }

  max(max: number, customMsg?: string) {
    return (value?: string) => {
      const isValid = Number(value) <= Number(max);
      return this.returnValidationResult(isValid, "max", customMsg, { max });
    };
  }

  minLength(minLength: number, customMsg?: string) {
    return (value?: string) => {
      const isValid = _.size(value) >= Number(minLength);
      return this.returnValidationResult(isValid, "minLength", customMsg, { minLength });
    };
  }

  maxLength(maxLength: number, customMsg?: string) {
    return (value?: string) => {
      const isValid = _.size(value) <= Number(maxLength);
      return this.returnValidationResult(isValid, "maxLength", customMsg, { maxLength });
    };
  }

  email(customMsg?: string) {
    return (value?: string) => {
      /* eslint-disable-next-line */
      const isValid = (/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/).test(value);
      return this.returnValidationResult(isValid, "email", customMsg);
    };
  }

  emailWithAccent(customMsg?: string) {
    return (value?: string) => {
      /* eslint-disable-next-line */
      const isValid = (/(?:[áéíóúüña-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[áéíóúüña-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/).test(value);
      return this.returnValidationResult(isValid, "email", customMsg);
    };
  }

  url(customMsg?: string) {
    return (value?: string) => {
      /* eslint-disable-next-line */
      const isValid = (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/).test(value);
      return this.returnValidationResult(isValid, "url", customMsg);
    };
  }

  number(customMsg?: string) {
    return (value?: string) => {
      const isValid = (/^[-+]?\d+(\.\d+)?$/).test(String(value));
      return this.returnValidationResult(isValid, "number", customMsg);
    };
  }

  integer(customMsg?: string) {
    return (value?: string) => {
      const isValid = (/^\d+$/).test(String(value));
      return this.returnValidationResult(isValid, "number", customMsg);
    };
  }

  sameAs(value2: string, customMsg?: string) {
    return (value?: string) => {
      const isValid = value === value2;
      return this.returnValidationResult(isValid, "sameAs", customMsg);
    };
  }
  notSameAs(value2: string, customMsg?: string) {
    return (value?: string) => {
      const isValid = value !== value2;
      return this.returnValidationResult(isValid, "notSameAs", customMsg);
    };
  }

  string(customMsg?: string) {
    return (value?: string) => {
      const isValid = _.isString(value);
      return this.returnValidationResult(isValid, "string", customMsg);
    };
  }

  dateValid(inputFormats: string | string[], customMsg?: string) {
    return (value?: string) => {
      const isValid = _.flow(
        arr => _.map(arr, format => DateService.isValid(value, format)),
        _.some
      )(_.toArray(inputFormats));
      return this.returnValidationResult(isValid, "dateValid", customMsg);
    };
  }

  dateRange(minDate?: string, maxDate?: string, customMsg?: string) {
    return (value?: string) => {
      const selectedDateObj = DateService.parse(value);
      const isValid =
        selectedDateObj >= DateService.parse(minDate) &&
        selectedDateObj <= DateService.parse(maxDate);
      return this.returnValidationResult(isValid, "dateRange", customMsg);
    };
  }

}


const ValidationInstance = new Validation();
export default ValidationInstance;
