import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


const WIDTH = 400;
const MARGIN = 20;

export const base = css`
  position: fixed;
  width: ${WIDTH}px;
  z-index: 2060;
  pointer-events: none;
  ${HELPERS.media.smallerThan.custom(WIDTH + MARGIN * 2)} {
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;


export const positions = {
  "top-center": css`
    bottom: auto;
    left: 50%;
    margin-left: -${WIDTH / 2}px;
  `,
  "top-right": css`
    bottom: auto;
    right: ${MARGIN}px;
  `,
  "top-left": css`
    bottom: auto;
    left: ${MARGIN}px;
  `,
  "bottom-center": css`
    bottom: 80px;
    left: 50%;
    margin-left: -${WIDTH / 2}px;
  `,
  "bottom-right": css`
    bottom: 80px;
    right: ${MARGIN}px;
  `,
  "bottom-left": css`
    bottom: 80px;
    left: ${MARGIN}px;
  `
};


export const itemWrapper = css`
    transition: all 200ms ease-out;
    overflow: hidden;
`;


export const item = css`
  pointer-events: all;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  background-color: #ccc;
  box-shadow: 0 0 1px #222;
  padding: 10px;
  display: flex;
`;


export const types = {
  info: css`
    box-shadow: 0 0 1px ${COLORS.info};
    background-color: ${COLORS.info};
  `,
  success: css`
    box-shadow: 0 0 1px ${COLORS.success};
    background-color: ${COLORS.success};
  `,
  error: css`
    box-shadow: 0 0 1px ${COLORS.error};
    background-color: ${COLORS.error};
  `,
  warning: css`
    box-shadow: 0 0 1px ${COLORS.warning};
    background-color: ${COLORS.warning};
  `,
  progress: css`
  `
};

export const itemHolder = css`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const itemContent = css`
  p {
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0;
  }
`;

export const itemHeader = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.56;
  text-align: left;
  color: #ffffff;
  margin-bottom: 5px;
`;


export const itemActions = css`
`;


export const closeIcon = css`
position: absolute;
top: 0px;
right: 0px;
width: 30px;
height: 30px;
line-height: 30px;
text-align: center;
cursor: pointer;
&:after {
  content:"✖";
  font-style: normal;
  color: white;
}
opacity: 0.7;
&:hover {
  opacity: 1;
}
`;


export const iconStyles = css`
color: #fff;
width: 30px;
margin-top: 3px;
`;
