// @ts-nocheck
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "@reduxjs/toolkit";
import autobind from "autobind-decorator";
import cx from "classnames";
import { useSpring, useTransition, animated, config } from "react-spring";
import { onlyUpdateForKeys, compose, pure } from "recompose";

import { alertActions } from "./Alert.slice";

import GroupItem from "./view/Alert.GroupItem.view";

const positions = [
  "top-center",
  "top-right",
  "top-left",
  "bottom-center",
  "bottom-left",
  "bottom-right"
];

const Alerts = ({ alerts, actions }) => {

  const {
    hide: handleHide,
    confirm: handleConfirm,
    cancel: handleCancel
  } = actions;

  const alertsGrouped = _.groupBy(alerts, "position");


  return (
    <div id="ui-alert">
      {
        _.map(positions, (position) =>
          <GroupItem
            handleHide={handleHide}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
            key={position}
            alerts={alertsGrouped[position]}
            position={position} />
        )
      }
    </div>
  );


};

Alerts.propTypes = {
  actions: PropTypes.object.isRequired,
  alerts: PropTypes.array.isRequired
};

Alerts.contextTypes = {
};


const mapStateToProps = (state) => {
  return {
    alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(alertActions, dispatch),
  dispatch
});

const withState = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withState,
  pure
)(Alerts);
