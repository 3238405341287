import _ from "lodash";

import { createLogger } from "redux-logger";


const BLACKLIST = [
  "##BROWSER/CLICK",
  "##BROWSER/WINDOW_BLUR",
  "##BROWSER/WINDOW_FOCUS",
  "##BROWSER/WINDOW_RESIZE",
  "##BROWSER/WINDOW_LOAD",
  "@@redux-form/FOCUS",
  "@@redux-form/BLUR",
  "@@redux-form/TOUCH",
  "@@redux-form/CHANGE",
  "@@redux-form/START_SUBMIT",
  "@@redux-form/STOP_SUBMIT",
  "@@redux-form/SET_SUBMIT_SUCCEEDED",
  "@@redux-form/SET_SUBMIT_FAILED",
  "@@redux-form/UPDATE_SYNC_ERRORS",
  "@@redux-form/REGISTER_FIELD",
  "@@redux-form/INITIALIZE",
  "@@redux-form/DESTROY",
  "##axios/REQUEST_SENT",
  "##axios/RESPONSE_RECEIVED",
  "##user/CLICK",
  "SHARED/LOADING_STATUS",
  "##app/VERSION_CHECK",
  "SOCKET_CONNECT_SUCCESS",
  "SOCKET_CONNECT_CLOSED",
  "SOCKET_CONNECT_ERROR",
  "LIVE_CHAT/CLOSE_TICKET",
  "LIVE_CHAT/ASSIGN_TICKET",
  "LIVE_CHAT/CREATE_SESSION",
  "LIVE_CHAT/SEND_DATA_FROM_SOCKET",
  "LIVE_CHAT/DESTROY"

];

const reduxLoggerMiddleware = createLogger({
  duration: true,
  diff: false,
  collapsed: true,
  logErrors: true,
  predicate: (getState, action) => (
    // !_.includes(BLACKLIST, action.type) ||
    action.log
  )
});


export default reduxLoggerMiddleware;
