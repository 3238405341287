import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface LoanRequestState {
  customerLimit: any,
  addNewBankAccountInProgress: boolean,
  issuedLoanDetails: any,
  hasSeenLimitModal: boolean,
  formLoading?: boolean,
  loadingMessage?: string,
  holidays?: string[],
  communicationPreferences?: any,
  productOptions?: any
  errorMsg?: string,
  currentTime?: string,
  reviewLink?: string,
  availableDates?: string[],
  loanRequestCardSelectionVisibility?: boolean,
  cards?: any[],
  product?: any,
  agreementType?: string,
  agreementsInProgress?: boolean,
  termsAndConditions?: string,
  loanExplanation?: any,
  customerAccounts?: any[],
  nextXDaysOfPayment?: any,
  loansBasedOnInstallment?: any[],
  loansBasedOnInstallmentWithDefinitionId?: any[],
  areInstallmentOptionsInProgress?: boolean,
  eligibleProductsOfCustomer?: any,
  customerHasIssuedOrArrangedLoan?: boolean,
  affiliateLink?: string
}

const initialState: LoanRequestState = {
  customerLimit: {},
  addNewBankAccountInProgress: false,
  issuedLoanDetails: {},
  hasSeenLimitModal: false
};

const loanRequestSlice = createSlice({
  name: "loanRequest",
  initialState,
  reducers: {
    togglePayloadFormLoading(state, action) {
      state.formLoading = action.payload.formLoading;
      state.loadingMessage = action.payload.loadingMessage;
    },
    getHolidaysList(state, action) {
      state.holidays = action.payload;
    },
    getCommunicationPreferences(state, action) {
      state.communicationPreferences = action.payload;
    },
    setProductOptions(state, action) {
      state.productOptions = action.payload;
    },
    setErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    passCurrentTime(state, action) {
      state.currentTime = action.payload;
    },
    getReviewLink(state, action) {
      state.reviewLink = action.payload;
    },
    getAvailableDates(state, action) {
      state.availableDates = action.payload;
    },
    toggleLoanRequestCardSelectionVisibility(state, action) {
      state.loanRequestCardSelectionVisibility = action.payload;
    },
    getCards(state, action) {
      state.cards = action.payload;
    },
    productChange(state, action) {
      state.product = action.payload;
    },
    getAgreementsStart(state, action) {
      state.agreementType = action.payload.agreementType;
      state.agreementsInProgress = true;
    },
    getAgreementsSuccess(state, action) {
      state.agreementType = "";
      state.termsAndConditions = action.payload.termsAndConditions;
      state.loanExplanation = action.payload.loanExplanation;
      state.agreementsInProgress = false;
    },
    getCustomerLimit(state, action) {
      state.customerLimit = action.payload;
    },
    passCustomerAccounts(state, action) {
      state.customerAccounts = action.payload;
    },
    getNextXDaysOfPayment(state, action) {
      state.nextXDaysOfPayment = action.payload;
    },
    getLoansBasedOnInstallmentLengthRequest(state, action) {
      state.loansBasedOnInstallment = undefined;
      state.areInstallmentOptionsInProgress = true;
    },
    getLoansBasedOnInstallmentLengthSuccess(state, action) {
      state.loansBasedOnInstallment = action.payload;
      state.areInstallmentOptionsInProgress = false;
    },
    toggleAddNewBankAccountInProgress(state, action) {
      state.addNewBankAccountInProgress = action.payload;
    },
    setLoanEligibleProductsOfCustomer(state, action) {
      state.eligibleProductsOfCustomer = action.payload;
    },
    getLoansBasedOnInstallmentLengthWithDefinitionIdRequest(state, action) {
      state.loansBasedOnInstallment = action.payload;
    },
    getLoansBasedOnInstallmentLengthWithDefinitionIdSuccess(state, action) {
      state.loansBasedOnInstallmentWithDefinitionId = action.payload;
    },
    getIssuedLoanForSuccessPage(state, action) {
      state.issuedLoanDetails = action.payload;
    },
    getCustomerHasIssuedOrArrangedLoan(state, action) {
      state.customerHasIssuedOrArrangedLoan = action.payload;
    },
    setHasSeenLimitModal(state, action) {
      state.hasSeenLimitModal = action.payload;
    },
    triggerCreateReviewLink: {
      reducer: state => state,
      prepare: (loanId, customerId) => {
        return {
          payload: {
            loanId,
            customerId
          }
        };
      }
    },
    loanRequestSuccess(state) {
      return state;
    },
    agreementAssignedSuccess(state) {
      return state;
    },
    getAffiliateLink(state, action) {
      state.affiliateLink = action.payload;
    },
    redirectToAffiliate(state) {
      return state;
    },
    destroy() {
      return initialState;
    }
  }
});


export default loanRequestSlice.reducer;
export const {
  togglePayloadFormLoading,
  getHolidaysList,
  getCommunicationPreferences,
  setProductOptions,
  setErrorMsg,
  passCurrentTime,
  getReviewLink,
  getAvailableDates,
  toggleLoanRequestCardSelectionVisibility,
  getCards,
  productChange,
  getAgreementsStart,
  getAgreementsSuccess,
  getCustomerLimit,
  passCustomerAccounts,
  getNextXDaysOfPayment,
  getLoansBasedOnInstallmentLengthRequest,
  getLoansBasedOnInstallmentLengthSuccess,
  toggleAddNewBankAccountInProgress,
  setLoanEligibleProductsOfCustomer,
  getLoansBasedOnInstallmentLengthWithDefinitionIdRequest,
  getLoansBasedOnInstallmentLengthWithDefinitionIdSuccess,
  getIssuedLoanForSuccessPage,
  getCustomerHasIssuedOrArrangedLoan,
  setHasSeenLimitModal,
  triggerCreateReviewLink,
  loanRequestSuccess,
  agreementAssignedSuccess,
  getAffiliateLink,
  redirectToAffiliate,
  destroy
} = loanRequestSlice.actions;
export const loanRequestActions = loanRequestSlice.actions;
