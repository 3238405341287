import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface DocumentsState {
  docErrorDisplayed: boolean
}

const initialState: DocumentsState = {
  docErrorDisplayed: false
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocErrorDisplayed(state) {
      state.docErrorDisplayed = true;
    },
    destroy() {
      return initialState;
    }
  }
});


export default documentsSlice.reducer;
export const {
  destroy
} = documentsSlice.actions;
export const documentsActions = documentsSlice.actions;
