import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface CreditSummaryState {
  loanId?: number,
  directTransferRecords?: any,
  agreementDownloadInProgress?: boolean,
  agreementContent?: string
}

const initialState: CreditSummaryState = {
};

const creditSummarySlice = createSlice({
  name: "creditSummary",
  initialState,
  reducers: {
    getDirectTransferPaymentRecordsRequest(state, action) {
      return state;
    },
    getDirectTransferPaymentRecordsSuccess(state, action: PayloadAction<any>) {
      state.loanId = action.payload.loanId;
      state.directTransferRecords = action.payload.directTransferRecords;
    },
    downloadAgreement(state) {
      state.agreementDownloadInProgress = true;
    },
    downloadAgreementSuccess(state) {
      state.agreementDownloadInProgress = false;
    },
    getAgreementsStart(state) {
      state.agreementContent = undefined;
    },
    getAgreementsSuccess(state, action) {
      state.agreementContent = action.payload;
    },
    printPdfAgreement(state, action) {
      return state;
    },
    destroy() {
      return initialState;
    }
  }
});


export default creditSummarySlice.reducer;
export const {
  getDirectTransferPaymentRecordsRequest,
  getDirectTransferPaymentRecordsSuccess,
  downloadAgreement,
  downloadAgreementSuccess,
  getAgreementsStart,
  getAgreementsSuccess,
  printPdfAgreement,
  destroy
} = creditSummarySlice.actions;
export const creditSummaryActions = creditSummarySlice.actions;
