// @ts-nocheck
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { css, keyframes } from "@emotion/react";

const ProgressBar = ({
  duration
}) => {
  const progress = keyframes`
    0 {
      width: 0%;
    }
    100% {
      width: 100%
    }
  `;

  return (
    <div
      style={{
        position: "absolute",
        opacity: 0.7,
        height: 4,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 0,
        display: "flex",
        alignItems: "stretch"
      }}
      className="ui progress tiny">
      <div
        css={css`
          width: 2px;
          background-color: #fff;
          animation: ${progress} ${duration}ms linear infinite;
        `}
        className="bar" />
    </div>
  );
};



// const [ percent ] = useSpring({
//   config: {
//     duration,
//     precision: 0
//   },
//   from: {  percent: 0  },
//   to: {  percent: 100  }
// });
// console.log("percent ", percent);

// return <Progress
//   style={{
//     position: "absolute",
//     bottom: 2,
//     left: 2,
//     right: 2
//   }}
//   percent={percent.value}
//   attached="bottom" />;

ProgressBar.propTypes = {
  duration: PropTypes.number
};

ProgressBar.contextTypes = {
};


export default ProgressBar;
