import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import DateService from "core/DateService";


interface HomeState {
  amount: number,
  paymentDay: number,
  paymentDates: string[],
  issueDate: string,
  installmentAmount: number,
  viewedInstallment: any,
  ekomiReviews: Array<any>,
  agreement: string,
  installments: Array<any>,
  calculationInProgress?: boolean
}

const now = (new Date()).toISOString();

export const initialState: HomeState = {
  amount: 400,
  paymentDay: 30,
  paymentDates: [ DateService.add(now, 30, "day", "ISO") ],
  issueDate: now,
  installmentAmount: 4,
  viewedInstallment: {},
  ekomiReviews: [],
  agreement: "",
  installments: []
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    changeAmount(state, action) {
      state.amount = action.payload;
    },
    passPaymentDates(state, action) {
      state.paymentDates = action.payload;
    },
    saveInstallmentsToState(state, action) {
      state.installments = action.payload;
    },
    showAgreementRequest(state, action) {
      return state;
    },
    showAgreementSuccess(state, action) {
      state.agreement = action.payload;
    },
    showAgreementFailure(state, action) {
      return state;
    },
    fetchEkomiReviewsRequest(state, action) {
      return state;
    },
    fetchEkomiReviewsSuccess(state, action) {
      state.ekomiReviews = action.payload;
    },
    calculateLoanRequest: {
      reducer: (state, action: PayloadAction<any>) => {
        state.amount = action.payload.amount;
        state.paymentDay = action.payload.paymentDay;
        state.paymentDates = action.payload.paymentDates;
        state.installmentAmount = action.payload.paymentDates.length;
        state.calculationInProgress = true;
      },
      prepare: (amount, paymentDates, paymentDay) => {
        return {
          payload: {
            amount,
            paymentDates,
            paymentDay
          }
        };
      }
    },
    calculateLoanSuccess(state, action) {
      state.viewedInstallment = action.payload;
      state.calculationInProgress = false;
    },
    calculateLoanFailure(state, action) {
      state.calculationInProgress = false;
    },
    submitPhoneCheckForm(state, action) {
      return state;
    },
    destroy() {
      return initialState;
    }
  }
});


export default homeSlice.reducer;
export const {
  changeAmount,
  passPaymentDates,
  saveInstallmentsToState,
  showAgreementRequest,
  showAgreementSuccess,
  showAgreementFailure,
  fetchEkomiReviewsRequest,
  fetchEkomiReviewsSuccess,
  calculateLoanRequest,
  calculateLoanSuccess,
  calculateLoanFailure,
  destroy
} = homeSlice.actions;
export const homeActions = homeSlice.actions;
