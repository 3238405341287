// @ts-nocheck

import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";


const styles = css`
`;


export default function FormLabel({ id, children, ...restProps }) {

  return (
    !!children && (
      <label
        htmlFor={id}
        {...restProps}
        css={styles}>
        {children}
      </label>
    )
  );
}


FormLabel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};
