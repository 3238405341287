// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { connect } from "react-redux";

import isEqual from "react-fast-compare";
import { withProps } from "recompose";

import { Modal } from "semantic-ui-react";

import { toggleModalState } from "../containers/App/App.slice";

type ComponentPropsType = {
  id: string;
  defaultOpen: boolean;
  onOpen: Function;
  onClose: Function;
}


const mapStateToProps = (state) => ({
  state: state.app.modalState
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    openModal: (modalId) => dispatch(toggleModalState(modalId, true)),
    closeModal: (modalId) => dispatch(toggleModalState(modalId, false))
  }
});


@connect(mapStateToProps, mapDispatchToProps)
class ModalProvider extends Component<ComponentPropsType> {

  // shouldComponentUpdate(nextProps) {
  //   return !isEqual(nextProps, this.props);
  // }

  handleOnOpen = (...args) => {
    const {
      id,
      children,
      actions
    } = this.props;
    _.invoke(children, "props.onOpen", ...args);
    actions.openModal(id);
  };
  handleOnClose = (...args) => {
    const {
      id,
      children,
      actions
    } = this.props;
    _.invoke(children, "props.onClose", ...args);
    actions.closeModal(id);
  };

  render() {
    // console.count("modal provider render");
    const {
      id,
      children,
      state = {},
      defaultOpen
    } = this.props;
    const modalState = _.defaultTo(state[id], defaultOpen);
    return <Modal
      closeIcon={<i className="icon close" />}
      {...children.props}
      open={modalState}
      onOpen={this.handleOnOpen}
      onClose={this.handleOnClose} />;
  }
}

ModalProvider.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  state: PropTypes.object,
  defaultOpen: PropTypes.bool,
  actions: PropTypes.object
};


export default ModalProvider;
