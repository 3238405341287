import { omit, reject } from "lodash";

import uuid from "uuid/v4";
import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";



const initialState: any[] = [];


const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    confirm(state) {
      return state;
    },
    show: {
      reducer: (state, action) => {
        state.push(action.payload);
      },
      prepare: ({
        header,
        content,
        alertProps,
        alertType = "info",
        dismissable = true,
        autoDismiss = 5000,
        icon,
        size,
        position = "top-right",
        actions
      }) => {
        return {
          payload: {
            id: uuid(),
            alertProps,
            header,
            content,
            alertType,
            dismissable,
            autoDismiss,
            icon,
            size,
            position,
            actions
          }
        };
      }
    },
    cancel(state) {
      return state;
    },
    hide(state, action) {
      return reject(state, {
        id: action.payload
      });
    },
    clear() {
      return initialState;
    }
  }
});


export default alertSlice.reducer;
export const {
  confirm,
  show,
  cancel,
  hide,
  clear
} = alertSlice.actions;
export const alertActions = alertSlice.actions;
