// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState, returnHasValueOrFocused } from "./formMetaHelpers";

import * as inputStyles from "./InputField.styles";
import * as styles from "./SelectField.styles";


function SelectField(props) {

  const {
    meta,
    input,
    help,
    label,
    options,
    floating = true,
    inputLeft,
    inputRight,
    fieldProps = {},
    inputProps = {},
    withoutLabel,
    ...restProps
  } = props;

  const id = generateFieldId("select", meta, restProps, input);
  const { isInvalid, isValid } = returnFieldState(meta);
  const hasValueOrFocused = returnHasValueOrFocused(input.value, meta);
  const hasFocused = meta.active;

  return (
    <FormField
      className="form-field field-select"
      isInvalid={isInvalid}
      {...fieldProps}>
      <FormWrapper
        className="form-wrapper"
        floating={floating}
        isInvalid={isInvalid}
        isValid={isValid}
        hasValueOrFocused={hasValueOrFocused}
        inputLeft={inputLeft}
        inputRight={inputRight}>

        {!withoutLabel && (
          <FormLabel
            css={styles.label}
            className="form-label"
            id={id}>
            {label}
          </FormLabel>
        )}

        <select
          id={id}
          readOnly={meta.submitting}
          css={[
            inputStyles.base,
            styles.base,
            withoutLabel && styles.withoutLabel,
            isInvalid && inputStyles.error,
            isValid && inputStyles.success
          ]}
          {...inputProps}
          {...input}>

          {!_.isNil(inputProps.placeholder) && (
            <option
              value="">
              {hasFocused ? inputProps.placeholder : ""}
            </option>
          )}

          {_.map(options, ({ id: optionId, value, description, ...rest }) => (
            <option
              key={optionId || value}
              id={optionId || value}
              value={value}
              {...rest}>
              {description}
            </option>
          ))}
        </select>

        {!!inputRight && (
          inputRight
        )}

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid} />


    </FormField>
  );
}


SelectField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  floating: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  inputLeft: PropTypes.object,
  inputRight: PropTypes.object,
  validate: PropTypes.array,
  label: PropTypes.string,
  help: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any.isRequired,
      description: PropTypes.any.isRequired,
      group: PropTypes.string
    })
  )
};

type OptionsItemType = {
  id: any;
  value: any;
  description: React.ReactNode | string;
} & React.OptionHTMLAttributes

type ComponentPropsType = {
  name: string;
  id: string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  fieldProps: Object;
  inputProps: React.InputHTMLAttributes;
  floating: boolean;
  withoutLabel: boolean;
  options: OptionsItemType[];
  inputProps: React.SelectHTMLAttributes;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  static Select = SelectField;

  render() {
    return (
      <Field
        component={SelectField}
        {...this.props} />
    );
  }
}

// export default SelectField;
