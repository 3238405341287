import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


export const base = css``;
export const calendarIcon = css`
position: absolute;
top: 50%;
right: 10px;
transform: translate(0, -50%);
pointer-events: none;
`;
