// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";
import cx from "classnames";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState } from "./formMetaHelpers";

import * as styles from "./RadioField.styles";
import * as inputStyles from "./InputField.styles";


function RadioField(props) {

  const {
    meta,
    input,
    help,
    label,
    withoutCircle,
    options,
    inline,
    basic,
    fieldProps = {},
    inputProps = {},
    ...restProps
  } = props;


  const { isInvalid, isValid } = returnFieldState(meta);

  return (
    <FormField
      className="form-field field-radio"
      isInvalid={isInvalid}
      {...fieldProps}>

      <FormLabel className="form-label">
        {label}
      </FormLabel>

      <FormWrapper className="form-wrapper" css={[
        styles.wrapper,
        inline && styles.inline
      ]}>

        {_.map(options, item => {
          const id = generateFieldId("radio", meta, restProps, input, item.value);
          return (
            <div
              key={id}
              className={cx("form-radio", {
                selected: input.checked || input.value === item.value
              })}
              css={[
                styles.radioItem,
                item.style
              ]}>

              <input
                id={id}
                readOnly={meta.submitting}
                css={[
                  styles.input
                ]}
                disabled={item.disabled}
                {...inputProps}
                {...input}
                value={item.value}
                checked={input.checked || input.value === item.value}
                type="radio" />

              <label
                css={[
                  styles.label,
                  !basic && styles.labelBordered,
                  isInvalid && styles.error,
                  isValid && styles.success,
                  withoutCircle && styles.withoutCircle
                ]}
                htmlFor={id}>
                {item.label}
              </label>

            </div>
          );
        })}

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid} />


    </FormField>
  );
}


RadioField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  validate: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired,
      style: PropTypes.any
    })
  ),
  label: PropTypes.string,
  help: PropTypes.string,
  withoutCircle: PropTypes.bool,
  basic: PropTypes.bool,
  inline: PropTypes.bool,
  defaultValue: PropTypes.bool
};


type RadioProps = {
  value: any;
  label: React.Node;
  style: Object;
  disabled: boolean;
}


type ComponentPropsType = {
  name: string;
  id: string;
  defaultValue: Boolean;
  inline: Boolean;
  withoutCircle: Boolean;
  basic: Boolean;
  options: RadioProps[];
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  fieldProps: Object;
  inputProps: React.InputHTMLAttributes;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  static Radio = RadioField;

  render() {
    return (
      <Field
        component={RadioField}
        {...this.props} />
    );
  }
}


// export default RadioField;
