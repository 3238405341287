// @ts-nocheck

import { isEmpty, memoize, join } from "lodash";


export const returnFieldState = (meta: Object = {}): {
  isInvalid: boolean;
  isValid: boolean;
} => {
  const {
    submitFailed,
    dirty,
    error,
    invalid,
    touched,
    valid
  } = meta;

  const isInvalid = (
    submitFailed || touched
  ) &&
    !!(error || invalid);

  const isValid = (dirty || touched) && !!(valid || !error);

  return {
    isInvalid,
    isValid
  };

};
export const returnHasValueOrFocused = (value, meta: Object = {}): boolean => {
  const {
    dirty,
    initial
  } = meta;
  if (value) return true;
  return !isEmpty(value) ||
    meta.active ||
    (isEmpty(meta.initial) && meta.dirty);

};



const generateIdFn = (prefix, formName, id, name, ...suffixes) => {
  return (
    [
      prefix,
      formName,
      (id || name),
      ...suffixes
    ].filter(Boolean)
      .join("-")
  );
};

const generateIdMemoized = memoize(
  generateIdFn,
  (...args) => {
    return join(args);
  }
);


export const generateFieldId = (prefix: string, meta: Object = {}, restProps: Object = {}, input: Object = {}, ...suffixes) => {
  return generateIdMemoized(prefix, meta.form, restProps.id, input.name, ...suffixes);
};

