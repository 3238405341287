import _ from "lodash";

import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import { MemberDto } from "@myk";

import ENV from "../../constants/ENV";


interface AppState {
  initInProgress: boolean,
  modalState: {
    [modalId: number]: string
  },
  isMobileMenuVisible: boolean,
  customerStatus?: string,
  hasActiveLoan?: boolean,
  memberDetails?: MemberDto,
  token?: string,
  activeRoute?: string,
  hasUserLoggedIn?: boolean,
  asyncRouteInProgress?: boolean,
  cookiePermissions?: any,
  version?: string
}

const initialState: AppState = {
  initInProgress: true,
  modalState: {},
  isMobileMenuVisible: false
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    sendErrorMessage(state: AppState, action: PayloadAction<string>) {
      state.customerStatus = action.payload;
    },
    setActiveLoanStatus(state, action) {
      state.hasActiveLoan = action.payload;
    },
    toggleMobileMenuVisibility(state, action) {
      state.isMobileMenuVisible = action.payload;
    },
    updateUser(state, action) {
      state.memberDetails = action.payload.memberDetails;
      state.token = action.payload.token;
    },
    toggleModalState: {
      reducer: (state, action: PayloadAction<any>) => {
        state.modalState[action.payload.modalId] = action.payload.modalState;
      },
      prepare: (modalId: string, modalState: boolean) => {
        return {
          payload: {
            modalId,
            modalState
          }
        };
      }
    },
    routeEnter(state, action) {
      state.activeRoute = action.payload;
    },
    routeExit(state, action) {
      return state;
    },
    authUserLogin(state, action) {
      state.memberDetails = action.payload.memberDetails;
      state.token = action.payload.token;
      state.hasUserLoggedIn = true;
    },
    authUserLogout(state) {
      return {
        ...state,
        memberDetails: undefined,
        token: undefined,
        hasUserLoggedIn: false,
        hasActiveLoan: false
      };
    },
    asyncRouteLoading(state) {
      state.asyncRouteInProgress = true;
    },
    asyncRouteLoaded(state) {
      state.asyncRouteInProgress = false;
    },
    initStart(state, action) {
      state.initInProgress = true;
      state.version = ENV.SOURCE_BUILD_NUMBER;
    },
    initFinish(state) {
      state.initInProgress = false;
    },
    applyCookiePermissions(state, action) {
      state.cookiePermissions = action.payload;
    },
    requestSent(state, action) {
      return state;
    },
    responseReceived(state, action) {
      return state;
    },
    routeChanged(state, action) {
      return state;
    },
    destroy() {
      return initialState;
    },
    updateCustomerStatus(state: AppState, action: PayloadAction<string>) {
      state.customerStatus = action.payload;
    }

  }
});

export default appSlice.reducer;
export const {
  sendErrorMessage,
  setActiveLoanStatus,
  toggleMobileMenuVisibility,
  updateUser,
  toggleModalState,
  routeEnter,
  routeExit,
  authUserLogin,
  authUserLogout,
  asyncRouteLoading,
  asyncRouteLoaded,
  initStart,
  initFinish,
  applyCookiePermissions,
  requestSent,
  responseReceived,
  routeChanged,
  destroy,
  updateCustomerStatus
} = appSlice.actions;
export const appActions = appSlice.actions;
