import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface OtpState {
  otpSendingInProgress?: boolean,
  purpose?: string,
  newPhone?: string,
  canRequestNewPin?: boolean
}

const initialState: OtpState = {
};

const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {
    requestNewPin(state, action) {
      state.otpSendingInProgress = true;
    },
    setPurpose(state, action: PayloadAction<string>) {
      state.purpose = action.payload;
    },
    setNewPhone(state, action: PayloadAction<string>) {
      state.newPhone = action.payload;
    },
    toggleRequestingNewPin: {
      reducer: (state, action: PayloadAction<any>) => {
        state.canRequestNewPin = action.payload.canRequestNewPin;
        state.otpSendingInProgress = action.payload.otpSendingInProgress;
      },
      prepare: (canRequestNewPin: boolean, otpSendingInProgress: boolean) => {
        return {
          payload: {
            canRequestNewPin,
            otpSendingInProgress
          }
        };
      }
    },
    destroy() {
      return initialState;
    }
  }
});


export default otpSlice.reducer;
export const {
  requestNewPin,
  setPurpose,
  setNewPhone,
  toggleRequestingNewPin,
  destroy
} = otpSlice.actions;
export const otpActions = otpSlice.actions;
