// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, useState, forwardRef } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";
import localeEs from "date-fns/locale/es"; // the locale you want
import localePl from "date-fns/locale/pl"; // the locale you want
import { type ReactDatePickerProps } from "react-datepicker";

import { Icon } from "kit/ui";

import DatePickerComponent from "../../components/DatePicker/DatePicker.component.lazy";

import DateService from "../../core/DateService";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState, returnHasValueOrFocused } from "./formMetaHelpers";

import * as styles from "./DatepickerField.styles";
import * as inputStyles from "./InputField.styles";

type InputPropTypes = {
  id: string;
  name: string;
  inputProps: any;
  floating: boolean;
  value: string;
  hasValueOrFocused: boolean;
  isOpenX: boolean;
  onClick: () => void
}

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef(({
  id,
  name,
  inputProps,
  floating,
  value,
  hasValueOrFocused,
  isOpenX,
  onClick
}: InputPropTypes, ref) => {

  const formattedValue = value && DateService.format(value, "DD-MM-YYYY");

  return (
    <input
      id={id}
      css={[
        inputStyles.input
      ]}
      {...inputProps}
      placeholder={(floating && !hasValueOrFocused) ? "" : inputProps?.placeholder}
      type="text"
      name={name}
      autoComplete="off"
      onFocus={isOpenX ? undefined : onClick}
      onClick={isOpenX ? undefined : onClick}
      onChange={_.noop}
      readOnly
      ref={ref}
      value={formattedValue} />
  );
});

// eslint-disable-next-line react/no-multi-comp
function DatePickerField({
  meta,
  input,
  help,
  label,
  floating = true,
  dateFormat = "yyyy-MM-dd",
  inputLeft,
  inputRight,
  fieldProps = {},
  inputProps = {},
  datePickerProps,
  ...restProps
}) {

  const [ isOpen, setIsOpen ] = useState(false);

  const id = generateFieldId("input", meta, restProps, input);
  const { isInvalid, isValid } = returnFieldState(meta);
  const hasValueOrFocused = returnHasValueOrFocused(input.value, meta);
  const hasFocused = meta.active;

  return (
    <>

      <FormField
        isInvalid={isInvalid}
        {...fieldProps}>

        <FormWrapper
          floating={floating}
          isInvalid={isInvalid}
          isValid={isValid}
          hasValueOrFocused={hasValueOrFocused || inputProps.type === "date"}
          inputLeft={inputLeft}
          inputRight={inputRight}>

          <FormLabel id={id}>
            {label}
          </FormLabel>

          <div
            css={[
              inputStyles.base,
              hasFocused && inputStyles.hasFocused,
              isInvalid && inputStyles.error,
              isValid && inputStyles.success
            ]}>

            <DatePickerComponent
              dateFormat={dateFormat}
              calendarStartDay={1}
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
              locale={localeEs}
              onChange={date => {
                const formatted = DateService.format(date, _.toUpper(dateFormat));
                input.onChange(formatted);
              }}
              onCalendarClose={() => setIsOpen(false)}
              onCalendarOpen={() => setIsOpen(true)}
              value={input.value || ""}
              customInput={
                <CustomInput
                  id={id}
                  name={input.name}
                  inputPtops={inputProps}
                  floating={floating}
                  hasValueOrFocused={hasValueOrFocused}
                  isOpen={isOpen} />
              }
              {...datePickerProps} />

            <Icon size="24" css={styles.calendarIcon} name="myk calendar" />

          </div>

          {/* <input
              id={id}
              readOnly={meta.submitting}
              css={STYLES.visuallyHidden}
              {...input} /> */}

        </FormWrapper>

        <FormHelp>{help}</FormHelp>

        <FormValidation
          errorMessage={meta.error}
          isVisible={isInvalid} />


      </FormField>
    </>

  );
}

DatePickerField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  datePickerProps: PropTypes.object,
  fieldProps: PropTypes.object,
  inputLeft: PropTypes.object,
  inputRight: PropTypes.object,
  digitsOnly: PropTypes.bool,
  floating: PropTypes.bool,
  validate: PropTypes.array,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  help: PropTypes.node
};



type ComponentPropsType = {
  name: string;
  id: string;
  dateFormat: string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  datePickerProps: ReactDatePickerProps;
  fieldProps: Object;
  floating: boolean;
  inputProps: React.InputHTMLAttributes;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  render() {
    return (
      <Field
        component={DatePickerField}
        {...this.props} />
    );
  }
}

// export default DatePickerField;
