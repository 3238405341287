import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface SharedState {
  fromZeroInterest?: boolean,
  requestsInProgress?: boolean
}

const initialState: SharedState = {
};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setFromZeroInterest(state, action: PayloadAction<boolean>) {
      state.fromZeroInterest = action.payload;
    },
    toggleRequestsInProgressStatus(state, action: PayloadAction<boolean>) {
      state.requestsInProgress = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default sharedSlice.reducer;
export const {
  setFromZeroInterest,
  toggleRequestsInProgressStatus,
  destroy
} = sharedSlice.actions;
export const sharedActions = sharedSlice.actions;
