import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";

import type { CardResponseDto, LoanDto, OptionalPaymentDto } from "@myk";


interface PaymentState {
  activeLoanInProgress: boolean,
  addCardLoading: boolean,
  customerCreditLimit?: number,
  cards?: CardResponseDto[],
  loan?: LoanDto,
  activeLoans: LoanDto[]
  paymentOptions?: OptionalPaymentDto[],
  paymentDate?: string,
  isLoading: boolean,
}

const initialState: PaymentState = {
  activeLoanInProgress: true,
  addCardLoading: false,
  activeLoans: [],
  isLoading: true
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    toggleAddCardLoading(state, action: PayloadAction<boolean>) {
      state.addCardLoading = action.payload;
    },
    putCustomerCreditLimit(state, action: PayloadAction<number>) {
      state.customerCreditLimit = action.payload;
    },
    getCards(state, action: PayloadAction<CardResponseDto[]>) {
      state.cards = action.payload;
    },
    getLoan(state, action: PayloadAction<LoanDto>) {
      state.loan = action.payload;
      state.activeLoanInProgress = false;
    },
    getActiveLoans(state, action: PayloadAction<LoanDto[]>) {
      state.activeLoans = action.payload;
    },
    getPaymentOptionsSuccess(state, action: PayloadAction<OptionalPaymentDto[]>) {
      state.paymentOptions = action.payload;
    },
    setPaymentDate(state) {
      state.paymentDate = new Date().toISOString();
    },
    initBizumPayment(state) {
      return state;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default paymentSlice.reducer;
export const {
  setPaymentDate,
  toggleAddCardLoading,
  putCustomerCreditLimit,
  getCards,
  getLoan,
  getActiveLoans,
  initBizumPayment,
  setIsLoading,
  destroy
} = paymentSlice.actions;
export const paymentActions = paymentSlice.actions;
