import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface CallMeState {
  radioOptionsLoading: boolean,
  daysOptions?: any,
  hoursOptions?: any
}

const initialState: CallMeState = {
  radioOptionsLoading: true
};

const callMeSlice = createSlice({
  name: "callMe",
  initialState,
  reducers: {
    passRadioOptions(state, action: PayloadAction<any>) {
      state.daysOptions = action.payload.daysOptions;
      state.hoursOptions = action.payload.hoursOptions;
      state.radioOptionsLoading = false;
    },
    radioOptionsLoading(state, action: PayloadAction<any>) {
      state.radioOptionsLoading = action.payload;
    },
    destroy() {
      return initialState;
    }
  }
});


export default callMeSlice.reducer;
export const {
  passRadioOptions,
  radioOptionsLoading,
  destroy
} = callMeSlice.actions;
export const callMeActions = callMeSlice.actions;
