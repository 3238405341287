// @ts-nocheck

import _ from "lodash";
import React, { Component } from "react";
import PropTypes from "prop-types";

import { css } from "@emotion/react";
import cx from "classnames";

import { COLORS, VARS } from "styles-js";


const styles = {

  wrapper: css`
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  position: relative;
  flex-wrap: wrap;
  > label {
    flex: 1 1 100%;
    font-size: 16px;
    color: ${COLORS.charcoalGrey};
  }
  > input[type=checkbox] + label {
    display: flex;
    font-size: 12px;
    color: ${COLORS.charcoalGrey};
    font-weight:  ${VARS.font.bold};
    line-height: 24px;
  }
  `,

  hasFocused: css`
    > label {
      color: ${COLORS.primary} !important;
    }
  `,

  floating: css`
  position: relative;
  label {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    transform: translate(12px, 18px);
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    color: ${COLORS.charcoalGrey};
    transition: all 120ms ease-out;
    letter-spacing: 0.3px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 auto;
    max-width: 96%;
    white-space: nowrap;
  }
  `,

  floatedUp: css`
  label {
    font-size: 12px;
    color:  ${COLORS.coolGrey};
    line-height: 16px;
    transform: translate(12px, 6px);
  }
  `,

  valid: css`
  label {
    /* color: ${VARS.form.successColor}; */
  }
  `,

  invalid: css`
  label {
    color: ${VARS.form.errorColor};
  }
  `

};



export default function FormWrapper({
  inputLeft,
  floating,
  hasValueOrFocused,
  inputRight,
  children,
  hasFocused,
  isInvalid,
  isValid,
  ...restProps
}) {

  return (
    <div
      className={cx({
        "field-valid": isValid,
        "field-invalid": isInvalid
      })}
      css={[
        styles.wrapper,
        floating && styles.floating,
        hasValueOrFocused && floating && styles.floatedUp,
        hasFocused && styles.hasFocused,
        isInvalid && styles.invalid,
        isValid && styles.valid
      ]}
      {...restProps}>
      {!!inputLeft && inputLeft}

      {children}

      {!!inputRight && inputRight}

    </div>
  );
}


FormWrapper.propTypes = {
  inputLeft: PropTypes.node,
  inputRight: PropTypes.node,
  isInvalid: PropTypes.bool,
  hasFocused: PropTypes.bool,
  isValid: PropTypes.bool,
  hasValueOrFocused: PropTypes.bool,
  floating: PropTypes.bool,
  children: PropTypes.node
};
