import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface RegisterState {
}

const initialState: RegisterState = {
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    destroy() {
      return initialState;
    }
  }
});


export default registerSlice.reducer;
export const {
  destroy
} = registerSlice.actions;
export const registerActions = registerSlice.actions;
