import _ from "lodash";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";



const arrowsDataUri = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath fill='%2363686e' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E%0A";


export const base = css`
  width: 100%;
  background-image: url("${arrowsDataUri}");
  background-repeat: no-repeat, repeat;
  background-position: right 8px top 50%, 0 0;
  background-size: 24px auto, 100%;
  padding: 14px 12px 8px;
  padding-right: 32px;
`;

export const option = css`
  width: 100%;
  padding-left: 200px;
`;

export const withoutLabel = css`
  width: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 48px;
  line-height: 26px;
  font-size: 24px;
  font-weight: 800;
  flex: 0 0 auto;
  option {
    font-size: 14px;
  }
`;

export const label = css`
  pointer-events: none;
`;


export const error = css`
`;


export const success = css`
`;
