import { createSlice } from "@reduxjs/toolkit";
// eslint-disable-next-line no-duplicate-imports
import type { PayloadAction } from "@reduxjs/toolkit";


interface LoanFlowState {
  journey?: any
}

const initialState: LoanFlowState = {
};

const loanFlowSlice = createSlice({
  name: "loanFlow",
  initialState,
  reducers: {
    getLoanJourney(state, action) {
      state.journey = action.payload;
    },
    cleanJourney(state, action) {
      state.journey = undefined;
    },
    skipThisStep: {
      reducer: (state, action: PayloadAction<any>) => state,
      prepare: (from, errorMessageContent) => {
        return {
          payload: {
            from,
            errorMessageContent
          }
        };
      }
    },
    navigate: {
      reducer: state => state,
      prepare: (to, replaceHistory, prevPath, additionalParams) => {
        return {
          payload: {
            to,
            replaceHistory,
            prevPath,
            additionalParams
          }
        };
      }
    },
    destroy() {
      return initialState;
    }
  }
});


export default loanFlowSlice.reducer;
export const {
  getLoanJourney,
  cleanJourney,
  skipThisStep,
  navigate,
  destroy
} = loanFlowSlice.actions;
export const loanFlowActions = loanFlowSlice.actions;
