// @ts-nocheck
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import autobind from "autobind-decorator";
import cx from "classnames";
import { config, useSpring, useTransition, animated } from "react-spring";
import { onlyUpdateForKeys, compose } from "recompose";

import { Icon } from "kit/ui";


import AlertProgressBar from "./Alert.ProgressBar.view";

import * as styles from "../Alert.styles.js";


const GroupItem = (props) => {

  const {
    alerts = [],
    position = "bottom-right",
    handleHide,
    handleConfirm,
    handleCancel
  } = props;

  const transitions = useTransition(
    alerts,
    item => item.id,
    {
      config: config.stiff,
      from: {
        opacity: 0,
        height: 0,
        marginBottom: 0,
        transform: "translate3d(0, 100%, 0)"
      },
      enter: item => async next => {
        const elem = document.getElementById(item.id) || {};
        /* eslint-disable-next-line */
        await next({
          opacity: 1,
          // height: "auto"
          height: _.defaultTo(elem.offsetHeight, "auto"),
          transform: "translate3d(0, 0, 0)",
          marginBottom: 10
        });
        /* eslint-disable-next-line */
        // await next({
        //   height: "initial"
        // });
      },
      leave: item => async(next) => {
        // const elem = document.getElementById(item.id) || {};
        /* eslint-disable-next-line */
        await next({
          opacity: 0,
          marginBottom: 0,
          transform: "translate3d(0, 100%, 0)"
        });
        /* eslint-disable-next-line */
        await next({
          height: 0
        });
      }
    }
  );

  return (
    <div
      css={[
        styles.base,
        styles.positions[position]
      ]}>

      {_.map(transitions, ({ item: alertItem, props: styleProps }) => (
        <div
          css={styles.itemWrapper}
          key={alertItem.id}>
          <animated.div
            style={styleProps}>
            {!!alertItem && (
              <div css={styles.itemHolder}>
                <div
                  css={[
                    styles.item,
                    styles.types[alertItem.alertType]
                  ]}
                  id={alertItem.id}>
                  {!!alertItem.icon && (
                    <Icon css={styles.iconStyles} name={`fas ${alertItem.icon}`} />
                  )}
                  <div
                    {...alertItem.alertProps}>
                    {!!alertItem.dismissable && (
                      <i
                        css={styles.closeIcon}
                        onClick={() => handleHide(alertItem.id)} />
                    )}

                    <div css={styles.itemContent}>
                      <div css={styles.itemHeader}>
                        {alertItem.header}
                      </div>
                      <p>{alertItem.content}</p>
                    </div>

                    {!_.isEmpty(alertItem.actions) && (
                      <div css={styles.itemActions}>
                        {_.map(alertItem.actions, (actionItem, index) => (
                          <button
                            size={_.defaultTo(alertItem.size, "tiny")}
                            content={`ACTION${index}`}
                            {...actionItem}
                            key={index}
                            onClick={() => {
                              handleConfirm(alertItem.id);
                              handleHide(alertItem.id);
                              _.invoke(actionItem, "onClick");
                            }} />
                        ))}
                      </div>
                    )}


                  </div>
                  {!!alertItem.autoDismiss && (
                    <AlertProgressBar duration={alertItem.autoDismiss} />
                  )}
                </div>

              </div>
            )}
          </animated.div>
        </div>
      ))}

    </div>
  );

};

GroupItem.propTypes = {
  alerts: PropTypes.array,
  position: PropTypes.string,
  handleHide: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

GroupItem.contextTypes = {
};


export default GroupItem;
