import { css } from "@emotion/react";


import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";


export const base = css`
`;
export const label = css`
width: 100%;
display: block;
display: flex;
align-items: center;
height: 72px;
flex-direction: row;
flex-wrap: nowrap;
padding: 8px;
background-color: #fff;
cursor: pointer;
border-radius: 2px;
  border: solid 1px ${COLORS.paleGreyThree};
  &:hover,
  &:focus,
  &:active {
    border-color: ${COLORS.azure};
  }
`;


export const fileInputPreview = css`
display: flex;
align-items: center;
width: 88px;
min-width: 88px;
height: 56px;
flex-direction: row;
flex-wrap: nowrap;
border: 1px solid rgba(0,0,0,0.1);
position: relative;
> svg {
  position: absolute;
  bottom: -5px;
  right: -5px;
}
  > img {
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 56px;
    background-color: #f5f5f5;
    margin: 0 auto;
  }
  button {
    font-size: 15px;
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
  }
`;

export const imgPlaceholder = css`
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  display: flex;
align-items: center;
justify-content: center;
`;
export const labelWrapper = css`
flex: 1 1 auto;
padding: 8px;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
align-items: flex-start;
overflow: hidden;
justify-content: center;
font-size: 12px;
font-weight: 500;
p {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}
`;


export const inputWrapper = css`
width: 100%;
height: 72px;
padding: 8px;
border-radius: 2px;
border: solid 1px ${COLORS.paleGreyThree};
background-color: white;
display: flex;
align-items: stretch;
`;

export const deleteIcon = css`
&:hover {
  path {
    fill: red;
  }
}
`;



export const fileInputLabel = css`
border-radius: 15px;
font-size: 16px;
padding: 5px 10px;
span {
  height: 35px;
}
`;



