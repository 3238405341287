import _ from "lodash";

import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

import { base } from "./InputField.styles";

import checked from "../../assets/svg/radios/checked.svg";
import unchecked from "../../assets/svg/radios/unchecked.svg";
import checkedDisabled from "../../assets/svg/radios/checked-disabled.svg";
import uncheckedDisabled from "../../assets/svg/radios/unchecked-disabled.svg";



export const wrapper = css``;
export const radioItem = css`
flex: 1 1 100%;
margin: 5px 0 !important;
label: {
  font-size: 20px;
}
`;
export const inline = css`
flex: 0 0 auto;
margin: 5px 0 !important;
&:nth-of-type(n+2) {
  margin-left: 20px;
}
`;

export const input = css`

  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0;

  &:not(:checked) + label:before {
    background-image: url(${unchecked});
  }
  &:checked + label {
    border-color: #223d58;
  }
  &:checked + label:before {
    background-image: url(${checked});
  }

  &:not(:checked)[disabled]{
    & + label {
      color: #b9bbbd !important;
      background-color: #f5f5f5;
    }
    & + label:before  {
      background-image: url(${uncheckedDisabled});
    }
    & + label svg,
    & + label img {
      opacity: 0.3 !important;
    }
  }
  &:checked[disabled]{
    & + label {
      background-color: #f5f5f5;
      color: #b9bbbd !important;
      border-color: #f5f5f5;
    }
    & + label:before  {
      background-image: url(${checkedDisabled});
    }
    & + label svg,
    & + label img {
      opacity: 0.3 !important;
    }
  }


  &:not([disabled]) + label:hover:before {
    box-shadow: 0 0 1px 1px #ccc;
    border-radius: 20px;
  }
  &:not([disabled]) + label:hover {
    color: ${COLORS.primary};
    border-color: ${COLORS.primary};
  }

  &[disabled] + label {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;



export const label = css`
  font-size: 14px;
  user-select: none;
  line-height: 1.4;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  /* padding: 10px; */
  color: ${COLORS.charcoalGrey};
  :before {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
`;


export const labelBordered = css`
  ${base};
  user-select: none;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px;
  color: ${COLORS.charcoalGrey};
  :before {
    content: "";
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
`;


export const withoutCircle = css`
&:before,
&:after {
  display: none !important;
}
`;


export const success = css`
`;
export const error = css`
  color: ${VARS.form.errorColor};
  border-color: ${VARS.form.errorColor};
`;
