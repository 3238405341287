const BLACKLIST = [
  "##BROWSER/CLICK",
  "##BROWSER/WINDOW_BLUR",
  "##BROWSER/WINDOW_FOCUS",
  "##BROWSER/WINDOW_RESIZE",
  "##BROWSER/WINDOW_LOAD",
  "##BROWSER/WINDOW_SCROLL",
  "@@redux-form-saga/PROMISE",
  "@@redux-form/FOCUS",
  "@@redux-form/BLUR",
  "@@redux-form/TOUCH",
  "@@redux-form/CHANGE",
  "@@redux-form/START_SUBMIT",
  "@@redux-form/STOP_SUBMIT",
  "@@redux-form/SET_SUBMIT_SUCCEEDED",
  "@@redux-form/SET_SUBMIT_FAILED",
  "@@redux-form/UPDATE_SYNC_ERRORS",
  "@@redux-form/REGISTER_FIELD",
  "@@redux-form/INITIALIZE",
  "@@redux-form/DESTROY",
  "##axios/REQUEST_SENT",
  "##axios/RESPONSE_RECEIVED",
  "##user/CLICK",
  "SHARED/LOADING_STATUS",
  "##app/VERSION_CHECK"
];

export default BLACKLIST;
