// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent } from "react";

import { Field, type BaseFieldProps } from "redux-form/dist/redux-form";

import FormField from "./Form.Field";
import FormLabel from "./Form.Label";
import FormHelp from "./Form.Help";
import FormWrapper from "./Form.Wrapper";
import FormValidation from "./Form.Validation";
import { generateFieldId, returnFieldState, returnHasValueOrFocused } from "./formMetaHelpers";

import * as inputStyles from "./InputField.styles";
import * as styles from "./TextAreaField.styles";


function TextAreaField(props) {

  const {
    meta,
    input,
    help,
    label,
    floating = true,
    inputLeft,
    inputRight,
    fieldProps = {},
    inputProps = {},
    ...restProps
  } = props;

  const clearInput = () => props.input.onChange("");

  const id = generateFieldId("textarea", meta, restProps, input);
  const { isInvalid, isValid } = returnFieldState(meta);
  const hasValueOrFocused = returnHasValueOrFocused(input.value, meta);

  return (
    <FormField
      className="form-field"
      {...fieldProps}>

      <FormWrapper
        className="form-wrapper"
        floating={floating}
        isInvalid={isInvalid}
        isValid={isValid}
        hasValueOrFocused={hasValueOrFocused}
        inputLeft={inputLeft}
        inputRight={inputRight}>

        <FormLabel
          className="form-label"
          id={id}>
          {label}
        </FormLabel>

        <textarea
          id={id}
          readOnly={meta.submitting}
          css={[
            inputStyles.base,
            styles.base,
            isInvalid && inputStyles.error,
            isValid && inputStyles.success
          ]}
          rows="3"
          {...inputProps}
          {...input} />

        {!!inputRight && (
          inputRight
        )}

      </FormWrapper>

      <FormHelp>{help}</FormHelp>

      <FormValidation
        errorMessage={meta.error}
        isVisible={isInvalid} />


    </FormField>
  );
}



TextAreaField.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  inputProps: PropTypes.object,
  fieldProps: PropTypes.object,
  inputLeft: PropTypes.object,
  inputRight: PropTypes.object,
  validate: PropTypes.array,
  label: PropTypes.string,
  floating: PropTypes.bool,
  help: PropTypes.node
};


type ComponentPropsType = {
  name: string;
  id: string;
  label: React.ReactNode | string;
  help: React.ReactNode | string;
  floating: boolean;
  fieldProps: Object;
  inputProps: React.TextareaHTMLAttributes;
}


// eslint-disable-next-line react/no-multi-comp
export default class FieldHoc extends Component<BaseFieldProps & ComponentPropsType> {

  static TextArea = TextAreaField;

  render() {
    return (
      <Field
        component={TextAreaField}
        {...this.props} />
    );
  }
}


// export default TextAreaField;
